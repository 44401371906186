// @ts-ignore
import { default as createDebug } from 'debug'

export function createLog(namespace: string) {
    const log = createDebug(namespace)
    const info = createDebug(namespace)
    const warn = createDebug(namespace)
    const error = createDebug(namespace)
    const debug = createDebug(namespace)

    log.log = console.log.bind(console)
    info.log = console.info.bind(console)
    warn.log = console.info.bind(console)
    error.log = console.error.bind(console)
    debug.log = console.debug.bind(console)

    return { log, info, warn, error, debug }
}

export const { log, info, warn, error, debug } = createLog('app')

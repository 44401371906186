<script setup lang="ts">
import { get } from 'lodash'
const userStore = useUserStore()
const auth = useAuth()
const wsDispatcher = useWebsocketDispatcher()

const { copy } = useClipboard()

function copyToClipboard(key: keyof UserStoreRootState) {
    copy(`"${get(userStore, key)}"`)
}
</script>

<template>
    <div class="debug-display">
        <span>
            {{
                {
                    unauthenticated: '🔴',
                    loading: '🟡',
                    authenticated: '🟢',
                }[userStore.status]
            }}
            userStore.status
        </span>
        <span>
            {{
                {
                    unauthenticated: '🔴',
                    loading: '🟡',
                    authenticated: '🟢',
                }[auth.status.value]
            }}
            auth.status
        </span>
        <span
            >{{
                {
                    CLOSED: '🔴',
                    OPEN: '🟢',
                    CONNECTING: '🟡',
                }[wsDispatcher.status.value]
            }}
            Websocket
        </span>
        <span>{{ userStore.isGuest ? '👤' : '👨🏼' }} {{ get(userStore, 'user.email') }}</span>
        <button @click="copyToClipboard('accessToken')">🔑 AccessToken</button>
        <button @click="copyToClipboard('refreshToken')">🔁 RefreshToken</button>
    </div>
</template>

<style lang="scss">
.debug-display {
    display: flex;
    flex-flow: column;
    z-index: 99999;
    background-color: hsla(0deg, 50%, 100%, 0.5);
    padding: 16px;
    gap: 3px;
}
</style>

import { cloneDeep, get } from 'lodash'
import {
    type Route,
    type Checkpoint,
    type GetRouteByUidRequest,
    type GetRoutesRequest,
    type TransformedCheckpoint,
    type RedeemQRCodeRequest,
    type RoutesResponse,
    type UUID,
    type RouteResponse,
    TransformedRoute,
    QrCodeCode,
} from '~/utils'

const log = createLog('app:pinia:qrHunt')

type RootState = {
    loading: boolean
    routes: Route[]
}

export const useQRHuntStore = defineStore('qrHunt', () => {
    const websocketDispatcher = useWebsocketDispatcher()
    const activityManagerStore = useActivitySessionManagerStore()

    // #region STATE

    const initialState: RootState = {
        loading: false,
        routes: [],
    }

    const state = reactive<RootState>(cloneDeep(initialState))
    state.loading = true
    // #endregion

    // #region WATCHERS
    let loadingTimeoutId: ReturnType<typeof setTimeout> | null = null
    watch(
        () => state.loading,
        (loading) => {
            if (loading) {
                loadingTimeoutId && clearTimeout(loadingTimeoutId)
                loadingTimeoutId = setTimeout(() => {
                    state.loading = false
                }, UI_UNLOCK_TIMEOUT)
            }
        },
        { immediate: true },
    )

    watch(websocketDispatcher.jsonData, (jsonData) => {
        const data = toRaw(jsonData)

        // prettier-ignore
        handleMessage<RoutesResponse>(RoutesResponseSchema, data, (data) => {
            state.loading = false
            state.routes = (data as RoutesResponse).content.resources
            log.debug('updated all routes')
        })
        ||
        handleMessage<RouteResponse>(RouteResponseSchema, data, (data) => {
            const newRoute: Route = data.content
            const idx = state.routes.findIndex(({ uid }) => uid === newRoute.uid)

            if (idx !== -1) {
                state.routes.splice(idx, 1, newRoute)
                log.debug('updated route')
            } else {
                state.routes.push(newRoute)
                log.debug('appended route')
            }
        })
        ||
        handleMessage<CheckpointFinishedResponse>(
            CheckpointFinishedResponseSchema,
            data,
            (data) => {
                const newRoute = data.content.route
                const newCheckpoint = data.content.checkpoint

                const route = findByUid(newRoute.uid)
                const checkpoint = route?.checkpoints.find(
                    (checkpoint) => checkpoint.uid === newCheckpoint.uid,
                )
                if (checkpoint) {
                    Object.assign(checkpoint, newCheckpoint)
                    log.debug('🍍[qr] updated checkpoint', route?.name, checkpoint.name)
                }
            },
        )
    })
    // #endregion

    // #region GETTERS
    function transformCheckpoint(
        checkpoint: Checkpoint,
        index: number,
        checkpoints: Checkpoint[],
    ): TransformedCheckpoint {
        const firstUnfinished = checkpoints
            .filter((checkpoint) => !checkpoint.finished)
            .find(Boolean)

        return {
            uid: checkpoint.uid,
            routeUid: checkpoint.routeUid,
            qrCodeUid: checkpoint.qrCode.uid,
            qrCodeCode: checkpoint.qrCode.code,
            contentUid: String(checkpoint.activity.params.contentUid),
            finished: checkpoint.finished,
            current: firstUnfinished?.uid === checkpoint.uid,
            name: String(checkpoint.attributes.name),
            description: String(checkpoint.attributes.description),
            order: checkpoint.order,
        }
    }

    function transformRoute(route: Route): TransformedRoute {
        const sessions = activityManagerStore.sessions
        const session = toRaw(sessions.find(({ params }) => params?.routeUid === route.uid))

        route.checkpoints.sort((a, b) => a.order - b.order)

        return {
            uid: route.uid,
            icon: String(route.attributes.icon),
            color: String(route.attributes.color),
            name: String(route.attributes.name),
            description: String(route.attributes.description),
            instructions: String(route.attributes.instructions),
            coins: Number(route.attributes.coins),
            stairs: Number(route.attributes.stairs),
            co2: Number(route.attributes.co2),
            state: get(session, 'state'),
            sessionUid: get(session, 'uid'),
            checkpoints: route.checkpoints.map(transformCheckpoint),
            pointValue: route.pointValue,
            power: Number(route.attributes.power),
            points: Number(route.attributes.points),
            trees: Number(route.attributes.trees),
        }
    }

    function findByUid(routeUid?: UUID): TransformedRoute | null {
        if (!routeUid) {
            return null
        }

        const route = toRaw(state.routes.find(({ uid }) => uid === routeUid))

        if (!route) {
            return null
        }

        return transformRoute(route)
    }

    function getSessionId(routeUid?: UUID): UUID | null {
        const route = findByUid(routeUid)

        if (!route) {
            log.error(`could not find route for uid: ${routeUid}`)
            return null
        }

        const session = activityManagerStore.findSessionByRouteUid(route.uid)

        if (!session) {
            log.error(`could not find session for route: ${routeUid}`)
            return null
        }

        return get(session, 'uid', null)
    }

    const getRoutes = computed<TransformedRoute[]>(() =>
        state.routes
            .map((route) => transformRoute(route))
            .sort((a: TransformedRoute, b: TransformedRoute) => {
                // @ts-ignore
                return (a.uid > b.uid) - (a.uid < b.uid)
            }),
    )

    const getRunningRoute = computed<TransformedRoute | null>(() => {
        const runningSession = activityManagerStore.getRunningQRHuntSession

        const routeUid = get(runningSession, 'params.routeUid')

        return findByUid(routeUid)
    })

    // #endregion

    // #region ACTIONS
    function $reset() {
        Object.assign(state, cloneDeep(initialState))
    }

    function actionGetRouteByUid(uid: UUID) {
        websocketDispatcher.send({
            target: 'qrhunt',
            content: {
                type: 'getRouteByUid',
                uid: uid,
            },
        } as GetRouteByUidRequest)
    }

    function actionGetRoutes() {
        state.loading = true

        websocketDispatcher.send({
            target: 'qrhunt',
            content: {
                type: 'getRoutes',
            },
        } as GetRoutesRequest)
    }

    function actionReedemQRCode(code: QrCodeCode | null): void {
        log.debug('actionReedeemQRcode', code)

        if (!code) {
            return
        }

        const message: RedeemQRCodeRequest = {
            target: 'qrhunt',
            content: {
                type: 'redeemCode',
                code: code,
            },
        }

        websocketDispatcher.send(message)
    }

    function actionStartRoute(routeUid?: UUID): void {
        log.debug('actionStartRoute')
        activityManagerStore.actionStartSession(routeUid)
    }

    function actionPauseRoute(routeUid?: UUID): void {
        log.debug('actionPauseRoute')
        const sessionUid = getSessionId(routeUid)
        if (!sessionUid) {
            return
        }

        activityManagerStore.actionPauseSession(sessionUid)
    }

    function actionResumeRoute(routeUid?: UUID): void {
        const sessionUid = getSessionId(routeUid)
        if (!sessionUid) {
            return
        }

        activityManagerStore.actionResumeSession(sessionUid)
    }

    function actionAbortRoute(routeUid?: UUID): void {
        const sessionUid = getSessionId(routeUid)
        if (!sessionUid) {
            return
        }

        activityManagerStore.actionAbortSession(sessionUid)
    }

    function actionFinishRoute(routeUid?: UUID): void {
        const sessionUid = getSessionId(routeUid)
        if (!sessionUid) {
            return
        }

        activityManagerStore.actionFinishSession(sessionUid)
    }

    // #endregion

    return {
        ...toRefs(state),
        transformRoute,
        transformCheckpoint,
        getRoutes,
        getRunningRoute,
        findByUid,
        actionReedemQRCode,
        actionGetRouteByUid,
        actionGetRoutes,
        actionStartRoute,
        actionPauseRoute,
        actionResumeRoute,
        actionAbortRoute,
        actionFinishRoute,
        $reset,
    }
})

// HMR
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useQRHuntStore, import.meta.hot))
}

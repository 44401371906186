import { cloneDeep, get } from 'lodash'
import { UnauthenticatedErrorSchema, type UnauthenticatedError } from '~/utils'
import { StorageSerializers, useStorage } from '@vueuse/core'

export type UserStoreRootState = {
    accessToken?: string | null
    refreshToken?: string | null
    user?: Record<string, any> | null
    status: 'unauthenticated' | 'authenticated' | 'loading'
}

export const useUserStore = defineStore('user', () => {
    const auth = useAuth()
    const websocketDispatcher = useWebsocketDispatcher()
    const { $toast } = useNuxtApp()

    const canTakeStairs = useStorage<boolean | null>(
        'canTakeStairs',
        null as boolean | null,
        localStorage,
        {
            serializer: StorageSerializers.boolean,
        },
    )

    // #region STATE
    const initalState = {
        accessToken: null,
        refreshToken: null,
        user: null,
        status: 'unauthenticated',
    } as UserStoreRootState

    const state = reactive<UserStoreRootState>(cloneDeep(initalState))

    // #endregion
    watch(websocketDispatcher.jsonData, (jsonData) => {
        const data = toRaw(jsonData)

        handleMessage<UnauthenticatedError>(UnauthenticatedErrorSchema, data, (error) => {
            logout()
        })
    })

    watchEffect(() => {
        const data = auth.data.value

        const errorCode = get(data, 'error.errorCode')
        if (errorCode === 'RefreshAccessTokenError') {
            // $toast.error(data?.error)
            logout()
        }

        state.accessToken = get(data, 'token.access', null)
        state.refreshToken = get(data, 'token.refresh', null)
        state.user = get(data, 'user')
    })

    watchEffect(() => {
        state.status = auth.status.value
    })

    watch(
        () => state.status,
        (status) => {
            switch (status) {
                case 'authenticated':
                    websocketDispatcher.open()
                    // websocketDispatcher.send(state.accessToken, { type: 'raw' })
                    break
                case 'unauthenticated':
                    if (['OPEN', 'CONNECTING'].includes(websocketDispatcher.status.value)) {
                        websocketDispatcher.close()
                    }
                    break
                case 'loading':
                default:
                    break
            }
        },
        { immediate: true },
    )

    // #region GETTER

    const isAuthed = computed(() => state.status === 'authenticated')

    const fullname = computed(() => {
        if (!state.user) {
            return null
        }

        return [state.user.firstName, state.user.lastName].join(' ')
    })

    const isGuest = computed(() => {
        if (!state.user) {
            return true
        }
        if (!state.user.groups) {
            return false
        }

        return state.user.groups
            .map((group) => group.replaceAll('/', ''))
            .includes(USER_GROUPS.GUEST)
    })

    // #endregion

    // #region ACTIONS
    function setAttibute(key: string, value: any) {
        // if (!this.user) { return; }
        // useFetch('/api/setUser', {
        //     method: 'PUT',
        //     headers: {
        //         'Accept' : 'application/json',
        //         'Content-Type': 'application/json',
        //         'Authentication': `Bearer ${this.accessToken}`
        //     }
        // })
        // this.$state.user.attributes[key] = value;
    }

    async function signInAsGuest() {
        state.status = 'loading'

        try {
            const headers = useRequestHeaders(['cookie']) as HeadersInit
            const { data, pending, error, refresh } = await useFetch(
                '/guest/public/api/create_guest',
                {
                    headers,
                    method: 'get',
                },
            )

            const payload = JSON.parse(atob(data.value.access_token.split('.')[1]))

            state.accessToken = data.value.access_token
            state.refreshToken = data.value.refresh_token
            state.user = {
                // name: payload.
                email: payload.email,
                uid: payload.sub,
                firstName: payload.given_name,
                lastName: payload.family_name,
                username: payload.preferred_username,
                groups: payload.groups,
            }

            state.status = 'authenticated'

            return true
        } catch (error) {
            state.status = 'unauthenticated'
        }
    }

    async function login(): Promise<void> {
        auth.signIn('keycloak', { callbackUrl: '/games', redirect: true })
    }

    function logout(): void {
        auth.signOut({ callbackUrl: '/' })
        $reset()
    }

    function $reset(): void {
        Object.assign(state, cloneDeep(initalState))
    }

    // #endregion

    const stateRefs = toRefs(state)

    return {
        ...stateRefs,
        canTakeStairs,
        fullname,
        isAuthed,
        isGuest,
        setAttibute,
        login,
        logout,
        signInAsGuest,
        $reset,
    }
})

import { NavigationFailureType, isNavigationFailure } from 'vue-router'

export default defineNuxtPlugin((nuxtApp) => {
    const headerStore = useHeaderStore()
    const router = useRouter()

    router.afterEach((to, from, failure) => {
        if (!isNavigationFailure(failure)) {
            headerStore.backLinkTo(null)
        }
    })

    return {
        provide: {
            headerBackTo: headerStore.backLinkTo,
        },
    }
})

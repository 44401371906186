import { cloneDeep } from 'lodash'
import {
    AchievementRequest,
    type Achievement,
    AchievementResponse,
    TransformedRoute,
} from '~/utils'

type RootState = {
    achievements: Achievement[]
}

export const useAchievementsStore = defineStore('achievements', () => {
    const websocketDispatcher = useWebsocketDispatcher()

    // #region STATE

    const initialState: RootState = {
        achievements: [
            {
                uid: '23fffab0-cf4e-4263-b732-1cc0267be820',
                name: 'Trophäe 1',
                description: 'Super passender Trophäenname',
                icon: 'circle-trophy-48x',
                color: '#99CDD1',
            },
            {
                uid: 'a021acc2-7a15-4a14-aa3f-d1b577b7f0af',
                name: 'Trophäe 2',
                description: 'Super passender Trophäenname',
                icon: 'circle-trophy-48x',
                color: '#E59EB2',
            },
            {
                uid: '4284f281-3c15-4ddf-8baf-418b45dcfdf2',
                name: 'Trophäe 3',
                description: 'Super passender Trophäenname',
                icon: 'circle-trophy-48x',
                color: '#F7C899',
            },
            {
                uid: 'b57c79af-633a-455c-ad12-f649c75e67af',
                name: 'Trophäe 4',
                description: 'Super passender Trophäenname',
                icon: 'circle-trophy-48x',
                color: '#E0C2E0',
            },
        ],
    }

    const state = reactive<RootState>(cloneDeep(initialState))

    // #endregion

    watch(websocketDispatcher.jsonData, (jsonData) => {
        const data = toRaw(jsonData)

        handleMessage<AchievementResponse>(AchievementResponseSchema, data, (data) => {
            Object.assign(state, data)
        })
    })

    function isFinishedRoute(route: TransformedRoute): boolean {
        return route.state === 'finished'
    }

    function routeToAchievement(route: TransformedRoute): Achievement {
        return {
            uid: route.uid,
            color: route.color,
            description: route.description,
            icon: route.icon,
            name: route.name,
        }
    }

    // #region GETTERS
    const getAchievements = computed(() => {
        const qrHuntStore = useQRHuntStore()

        return qrHuntStore.getRoutes.filter(isFinishedRoute).map(routeToAchievement)
    })
    // #endregion

    // #region ACTIONS
    function $reset(): void {
        Object.assign(state, cloneDeep(initialState))
    }

    function actionGetAchievements(): void {
        const message: AchievementRequest = {
            target: 'achievement_service',
            content: {
                type: 'getAchievements',
                activity: 'qrhunt',
            },
        }

        websocketDispatcher.send(message)
    }
    // #endregion

    return {
        ...toRefs(state),
        actionGetAchievements,
        getAchievements,
        $reset,
    }
})

<script setup lang="ts"></script>

<template>
    <div class="button-group">
        <slot />
    </div>
</template>

<style lang="scss">
.button-group {
    display: flex;
    flex-flow: column;
    width: 100%;
    gap: v(s-12);
}
</style>

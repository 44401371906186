import { get } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { useToast } from 'vue-toastification'
import { ZodSchema } from 'zod'
const toast = useToast()

const wsUrl = ref()
const jsonData = ref()
const log = createLog('app:websocketDispatcher')

const websocket = useWebSocket(wsUrl, {
    immediate: false,
    protocols: ['access_token'],
    heartbeat: {
        message: 'ping',
        interval: 30000,
        pongTimeout: 2500,
    },
    autoReconnect: {
        retries: 5,
        delay: 5000,
        onFailed() {
            const userStore = useUserStore()
            log.error('Failed to connect WebSocket after 5 retries, triggered logout')
            // toast.error('Failed to connect WebSocket after 5 retries, triggered logout')
            setTimeout(() => {
                userStore.logout()
            }, 3000)
        },
    },
    onError(ws, event) {
        log.log('🚫', event)
        // toast.error('[WS] Error: ' + JSON.stringify(event))
    },
    onMessage(ws, event) {
        // log.log("✉️", event);
        try {
            const data = JSON.parse(event.data)
            jsonData.value = data
        } catch (err) {}
    },
    onConnected(ws) {
        const userStore = useUserStore()
        const accessToken = get(userStore, 'accessToken')
        if (userStore.status === 'authenticated' && accessToken) {
            send(accessToken, { type: 'raw' })
            log.log('🔔')
            // toast.success('Connected')
        } else {
            log.error('user')
        }
    },
    onDisconnected(ws, event) {
        log.log('🔕')
        // toast.warning('Disconnected')
    },
    // heartbeat: {
    //     message: 'ping',
    //     interval: 1000,
    //     pongTimeout: 1000,
    // },
})

export type WebsocketSendOptions = {
    type: 'raw' | 'json'
    timeout?: number
}

function send(message: any, options: WebsocketSendOptions = { type: 'json' }) {
    switch (options.type.toLowerCase()) {
        case 'raw':
            websocket.send(message)
            break
        case 'json':
        default:
            websocket.send(JSON.stringify(message))
            break
    }
}

export function useWebsocketDispatcher() {
    const runtimeConfig = useRuntimeConfig()

    if (!wsUrl.value) {
        wsUrl.value = runtimeConfig.public.websocketDispatcherUrl
    }

    // const { status, data, send, open, close } = websocket

    async function sendAsync(
        message: any,
        options: WebsocketSendOptions = { type: 'json', timeout: 1000 },
    ) {
        const messageId = uuidv4()

        switch (options.type.toLowerCase()) {
            case 'raw':
                throw 'Not implemented'

                // const rawResponse = await until(websocket.data)
                //     .toMatch(
                //         ({ correlationId }) => correlationId === originalCorrelationId,
                //         { timeout: 30 * 1000 }
                //     )
                websocket.send(message)

                return
            // return rawResponse
            case 'json':
            default:
                websocket.send(
                    JSON.stringify({
                        ...message,
                        messageId,
                    }),
                )

                let correlatedResponse = null
                await until(websocket.data).toMatch(
                    (value) => {
                        try {
                            const jsonData = JSON.parse(value)
                            const isMatch = get(jsonData, 'correlationId') === messageId

                            if (isMatch) {
                                correlatedResponse = value
                            }

                            return isMatch
                        } catch (err) {
                            correlatedResponse = null
                            return false
                        }
                    },
                    { timeout: options.timeout, throwOnTimeout: true },
                )

                return correlatedResponse
        }
    }

    return {
        ...websocket,
        jsonData,
        send,
        sendAsync,
    }
}

watch(jsonData, (jsonData) => {
    handleMessage<WsError>(ErrorSchema, jsonData, (data) => {
        log.error(`(${data.content.errorType}) ${data.content.message}`)
        // toast.error(data.content.message as String)
    })
})

export function onWSMessage<T>(schema: ZodSchema, fn: (data: T) => void) {
    return watch(jsonData, (_data) => {
        handleMessage<T>(schema, _data, fn)
    })
}

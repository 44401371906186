import { cloneDeep } from 'lodash'
import { acceptHMRUpdate } from 'pinia'
import { type Reward, type RewardsRequest, type UUID, RewardsResponse } from '~/utils'

type RootState = {
    rewards: Reward[]
    uwu: string | null
}

export const useRewardsStore = defineStore('rewards', () => {
    const websocketDispatcher = useWebsocketDispatcher()

    // #region STATE
    const initialState: RootState = {
        rewards: [
            {
                uid: 'b16dc324-39cd-4dd9-8bd7-9ca02bd88a5c',
                name: 'Frei Rutschen',
                description: `
                    <p>At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est.</p>
                `,
                image: null,
                redeemUrl: 'https://example.com',
                priceInCoins: 50,
                redeems: Number.POSITIVE_INFINITY,
            },
            {
                uid: '00eee035-cc6d-4860-a9ad-8ac90924f388',
                name: 'Frei Rutschen',
                description: 'Welcome Reward',
                image: null,
                redeemUrl: 'https://example.com',
                priceInCoins: 50,
                redeems: Number.POSITIVE_INFINITY,
            },
        ],
        uwu: null,
    }

    const state = reactive<RootState>(cloneDeep(initialState))

    // #endregion

    watch(websocketDispatcher.jsonData, (jsonData) => {
        const data = toRaw(jsonData)

        handleMessage<RewardsResponse>(RewardsResponseSchema, data, (data) => {
            Object.assign(state.rewards, data)
        })
    })

    // #region GETTERS
    // TODO Typing
    const findById = computed(() => {
        return (rewardUid: UUID) => state.rewards.find(({ uid }) => rewardUid === uid)
    })

    // #endregion

    // #region ACTIONS
    function $reset(): void {
        Object.assign(state, cloneDeep(initialState))
    }

    function actionGetRewards(): void {
        const message: RewardsRequest = {
            target: 'rewards',
            content: {
                type: 'getRewards',
            },
        }

        websocketDispatcher.send(message)
    }
    // #endregion

    return {
        ...toRefs(state),
        actionGetRewards,
        findById,
        $reset,
    }
})

// HMR
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useRewardsStore, import.meta.hot))
}

import { z } from 'zod'

export const TextComponentSchema = z.object({
    type: z.literal('text'),
    style: z.string().nullable().optional(),
    class: z.string().nullable().optional(),
    html: z.string().nullable(),
})

export type ComponentBuilderTextComponent = z.infer<typeof TextComponentSchema>

export const ImageComponentSchema = z.object({
    type: z.literal('image'),
    src: z.string(),
    srcset: z.string().nullable().optional(),
    sizes: z.string().nullable().optional(),
    width: z.union([z.string(), z.number(), z.null(), z.undefined()]),
    height: z.union([z.string(), z.number(), z.null(), z.undefined()]),
    alt: z.string().nullable().optional(),
    style: z.string().nullable().optional(),
    class: z.string().nullable().optional(),
})

export type ComponentBuilderImageComponent = z.infer<typeof ImageComponentSchema>

export const ComponentSchema = z.union([TextComponentSchema, ImageComponentSchema])

export type ComponentBuilderComponents = z.infer<typeof ComponentSchema>

export const ContentSchema = z.object({
    resourceType: z.literal('content'),
    uid: UUIDSchema,
    attributes: AttributesSchema.nullable(),
    content: z.array(ComponentSchema),
})

export type Content = z.infer<typeof ContentSchema>

// #region REQUEST
export const AcknowledgeContentRequestSchema = z.object({
    target: z.literal('content'),
    content: z.object({
        type: z.literal('acknowledgeContent'),
        uid: UUIDSchema,
    }),
})

export type AcknowledgeContentRequest = z.infer<typeof AcknowledgeContentRequestSchema>
// #endregion

// #region RESPONSE
export const ContentResponseSchema = z.object({
    type: z.literal('resource'),
    content: ContentSchema,
})

export type ContentResponse = z.infer<typeof ContentResponseSchema>
// #endregion

// #region ERRORS

export const ContentNotFoundErrorSchema = ErrorSchema.extend({
    content: ErrorSchema.shape.content.extend({
        errorType: z.literal('contentNotFound'),
    }),
})

export const ContentDoesNotMatchSessionErrorSchema = ErrorSchema.extend({
    content: ErrorSchema.shape.content.extend({
        errorType: z.literal('contentDoesNotMatch'),
    }),
})

export type NoRunningSessionError = z.infer<typeof NoRunningSessionErrorSchema>
export type ContentNotFoundError = z.infer<typeof ContentNotFoundErrorSchema>
export type ContentDoesNotMatchSessionError = z.infer<typeof ContentDoesNotMatchSessionErrorSchema>
// #endregion

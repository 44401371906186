import { last } from 'lodash'
import { AsyncComponentLoader, defineAsyncComponent } from 'vue'

const routes = ref<QRHuntRouteLocation[]>([
    {
        name: 'overview',
        component: () => import('~/components/qrHuntViews/QRHuntRouteOverview.vue'),
        meta: {
            navbarEnabled: true,
        },
    },
    {
        name: 'cancel',
        component: () => import('~/components/qrHuntViews/QRHuntCancelDialog.vue'),
        meta: {
            navbarEnabled: true,
        },
    },
    {
        name: 'content',
        component: () => import('~/components/qrHuntViews/QRHuntQRContent.vue'),
        meta: {
            navbarEnabled: false,
        },
    },
    {
        name: 'detail',
        component: () => import('~/components/qrHuntViews/QRHuntQRDetail.vue'),
        meta: {
            navbarEnabled: true,
        },
    },
    {
        name: 'finished',
        component: () => import('~/components/qrHuntViews/QRHuntRouteFinished.vue'),
        meta: {
            navbarEnabled: true,
        },
    },
    {
        name: 'query-abilities',
        component: () => import('~/components/qrHuntViews/QRHuntQueryAbilities.vue'),
        meta: {
            navbarEnabled: true,
        },
    },
    {
        name: 'scan',
        component: () => import('~/components/qrHuntViews/QRHuntScan.vue'),
        meta: {
            navbarEnabled: false,
        },
    },
    {
        name: 'scan-failure',
        component: () => import('~/components/qrHuntViews/QRHuntScanFailure.vue'),
        meta: {
            navbarEnabled: false,
        },
    },
])

const routeHistory = ref<QRHuntRouteNormalized[]>([])

const route = computed(() => last(routeHistory.value))

const component = computed(() => {
    const match = routes.value.find(({ name }) => {
        if (!route.value?.name) {
            return null
        }
        return name === route.value.name
    })

    if (!match) {
        return null
    }

    return defineAsyncComponent(match.component)
})

function createRouteLocation(route: QRHuntRoute): QRHuntRouteNormalized {
    route = toValue(route)

    if (typeof route === 'string') {
        const matched = routes.value.find(({ name }) => name === route)
        return {
            routeType: 'qrhunt',
            name: route,
            params: {},
            meta: matched?.meta,
        }
    }

    if (typeof route === 'object') {
        const matched = routes.value.find(({ name }) => name === route.name)

        return {
            routeType: 'qrhunt',
            name: route.name,
            params: route.params,
            meta: matched?.meta,
        } as QRHuntRouteNormalized
    }

    throw `route format "${typeof route}" is not implemented`
}

function push(route: QRHuntRoute): void {
    route = createRouteLocation(route)
    routeHistory.value.push(route)
}

function replace(route: QRHuntRoute): void {
    route = createRouteLocation(route)
    routeHistory.value.splice(-1, 1, route)
}

function back(): void {
    go(-1)
}
function go(count: number): void {
    const historyLength = routeHistory.value.length
    if (count >= 0) {
        return
    } // forward navigation is unsupported
    if (historyLength <= 1) {
        return
    } // can't go back further
    if (count <= 0 && !(historyLength - Math.abs(count) >= 1)) {
        return
    } // can't go back further

    routeHistory.value.splice(count, Math.abs(count))
}

push('overview')

export function useQRHuntRouter() {
    return {
        go,
        back,
        push,
        replace,
        route,
        routes,
        component,
        routeHistory,
    }
}

export type QRHuntRouteName =
    | 'cancel'
    | 'content'
    | 'detail'
    | 'query-abilities'
    | 'finished'
    | 'overview'
    | 'scan'
    | 'scan-failure'

export type QRHuntRouteNormalized = {
    routeType?: 'qrhunt'
    name: QRHuntRouteName
    params?: object
    replace?: boolean
    readonly meta?: Object
}

export type QRHuntRoute = QRHuntRouteName | QRHuntRouteNormalized

export type QRHuntRouteLocation = {
    name: QRHuntRouteName
    component: AsyncComponentLoader
    meta?: Object
}

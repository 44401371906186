import { z } from 'zod'
import { type Json } from './schemas/Common'

export function handleMessage<T>(schema: z.ZodTypeAny, data: Json, predicate?: (data: T) => void) {
    if (!data) {
        return false
    }

    const result = schema.safeParse(data)

    if (result.success) {
        typeof predicate === 'function' && predicate(data as T)
        return true
    }

    return false
}

import type { RouterConfig } from '@nuxt/schema'
import { RouteRecordRaw } from 'vue-router'

// https://router.vuejs.org/api/interfaces/routeroptions.html
export default <RouterConfig>{
    routes: (_routes) =>
        _routes.concat([
            {
                name: 'qrCode',
                path: '/games/qr-hunt/qr/:code',
                redirect(to) {
                    const { qrCode } = useQRCode()
                    qrCode.value = Number(to.params.code)

                    return {
                        name: PAGE.QRHUNT_INDEX,
                    }
                },
            },
        ] as RouteRecordRaw[]),
}

<script setup lang="ts">
import { CSSColor } from '~/types'
const log = createLog('app:icons')

type AutoColorValue = 'stroke' | 'fill' | null

export type Props = {
    name: string
    fluid?: boolean
    circle?: CSSColor
    autoColor?: AutoColorValue | Array<AutoColorValue>
}

const props = withDefaults(defineProps<Props>(), {
    fluid: false,
    circle: undefined,
    autoColor: null,
})

const icon = ref<string | Record<string, any>>('')

async function getIcon() {
    const iconsImport = import.meta.glob(['assets/icons/**/**.svg'], {
        as: 'raw',
        eager: false,
    })

    const matchedFilePath = Object.keys(iconsImport).find((filePath) => {
        const fullFilename = filePath.split('/').pop()
        if (!fullFilename) {
            return false
        }
        const [filename, extension] = fullFilename.split('.')
        if (!filename) {
            return false
        }
        return filename === props.name
    })

    if (!matchedFilePath) {
        log.warn(`Icon '${props.name}' doesn't exist in specified globs`)
        return
    }

    try {
        const rawIcon = await iconsImport[matchedFilePath]()
        icon.value = rawIcon
    } catch (err) {
        log.error(`Icon '${props.name}' could not be loaded`)
    }
}

await getIcon()

watchEffect(getIcon)
</script>

<template>
    <i
        :class="{
            'icon--fluid': !!fluid,
            // @ts-ignore
            'icon--auto-stroke': [].concat(autoColor).includes('stroke'),
            // @ts-ignore
            'icon--auto-fill': [].concat(autoColor).includes('fill'),
            'icon--circle': circle !== undefined,
        }"
        :style="{
            backgroundColor: circle,
        }"
        v-html="icon"
        class="icon"
    />
</template>

<style lang="scss">
.icon {
    display: inline-flex;

    &--auto-fill {
        svg [fill]:not([fill='none']):not([fill='transparent']) {
            fill: currentColor;
        }
    }

    &--auto-stroke {
        svg [stroke]:not([stroke='none']):not([fill='transparent']) {
            stroke: currentColor;
        }
    }

    &--fluid {
        svg {
            width: 100%;
            height: auto;
        }
    }

    &--circle {
        border-radius: 50%;
        aspect-ratio: 1;
    }
}
</style>

import { z } from 'zod'

export const UserStatsSchema = z.object({
    coins: z.number().optional(),
    points: z.number().optional(),
    stairs: z.number().optional(),
    power: z.number().optional(),
    co2: z.number().optional(),
    trees: z.number().optional(),

    uid: UUIDSchema.nullable(),
})

export const UserStatsRequestSchema = z.object({
    target: z.literal('user_stats'),
    content: z.object({
        type: z.literal('readByUserUid'),
    }),
})

export const UserStatsResponseSchema = z.object({
    type: z.literal('userStats'),
    content: UserStatsSchema,
})

export type UserStats = z.infer<typeof UserStatsSchema>
export type UserStatsRequest = z.infer<typeof UserStatsRequestSchema>
export type UserStatsResponse = z.infer<typeof UserStatsResponseSchema>

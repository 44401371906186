import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig()

    Sentry.init({
        enabled: process.env.NODE_ENV === 'production',
        app: nuxtApp.vueApp,
        dsn: config.public.sentry.dsn,
        environment: config.public.sentry.environment,
        // Performance Monitoring
        tracesSampleRate: 0.25, // Capture 25% of the transactions, in production
        sampleRate: 1.0,
    })
})

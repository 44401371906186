import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_AOuQ1DYzjk from "/app/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import header_client_MWhO1uME4D from "/app/src/plugins/header.client.ts";
import qrHunt_client_wW2iXrAJ1i from "/app/src/plugins/qrHunt.client.ts";
import sentry_client_KAXFuL2wum from "/app/src/plugins/sentry.client.ts";
import toast_client_5GxzM6P4QL from "/app/src/plugins/toast.client.ts";
import uiText_rmCHF81ZMw from "/app/src/plugins/uiText.ts";
import wsData_client_6IxfYLfLR2 from "/app/src/plugins/wsData.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_AOuQ1DYzjk,
  chunk_reload_client_UciE0i6zes,
  header_client_MWhO1uME4D,
  qrHunt_client_wW2iXrAJ1i,
  sentry_client_KAXFuL2wum,
  toast_client_5GxzM6P4QL,
  uiText_rmCHF81ZMw,
  wsData_client_6IxfYLfLR2
]
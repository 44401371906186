import { cloneDeep } from 'lodash'
import { type RouteLocationRaw } from 'vue-router'

export type HeaderBackLink = QRHuntRoute | RouteLocationRaw | symbol | null

type RootState = {
    backLink: HeaderBackLink
}

export const useHeaderStore = defineStore('header', () => {
    const initialState: RootState = {
        backLink: null,
    }

    const state = reactive<RootState>(cloneDeep(initialState))

    function $reset() {
        Object.assign(state, cloneDeep(initialState))
    }

    function backLinkTo(to: HeaderBackLink = null) {
        state.backLink = to
    }

    return {
        ...toRefs(state),
        backLinkTo,
        $reset,
    }
})

// HMR
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useHeaderStore, import.meta.hot))
}

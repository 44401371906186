<script setup lang="ts">
definePageMeta({
    layout: 'no-nav',
})

type Props = {
    error: Record<string, any>
}
const props = defineProps<Props>()

const { $t, $tExists } = useNuxtApp()

const handleError = () => clearError({ redirect: '/' })

const statusMessage = computed<string>(() => {
    const key = `page.error.code.${props.error.statusCode}.statusMessage`
    const fallback = $t(`page.error.code.default.statusMessage`)

    // if (props.error.statusMessage) {
    //     return props.error.statusMessage
    // }

    return $tExists(key) ? $t(key) : fallback
})

const subline = computed<string>(() => {
    const key = `page.error.code.${props.error.statusCode}.subline`
    const fallback = $t(`page.error.code.default.subline`)

    return $tExists(key) ? $t(key) : fallback
})

const description = computed<string>(() => {
    const key = `page.error.code.${props.error.statusCode}.description`
    const fallback = $t(`page.error.code.default.description`)

    return $tExists(key) ? $t(key) : fallback
})
</script>

<template>
    <div>
        <NuxtLayout
            :key="$route.fullPath"
            name="no-nav"
        >
            <div class="page-error">
                <Icon
                    name="stop-48x"
                    circle="#E59EB2"
                    fluid
                    class="page-error__icon"
                />

                <RichText class="page-error__text rich-text--compact">
                    <p class="small">{{ subline }}</p>
                    <h1>{{ error.statusCode }} - {{ statusMessage }}</h1>
                    <div v-html="description" />
                </RichText>

                <ButtonGroup class="page-error__buttons">
                    <AppButton @click="handleError">
                        {{ $t('page.error.buttons.back') }}
                    </AppButton>
                </ButtonGroup>
            </div>
        </NuxtLayout>
    </div>
</template>

<style lang="scss">
.page-error {
    height: 100%;
    display: grid;
    grid-template-rows: auto 30px auto minmax(48px, 1fr) auto;
    grid-template-areas:
        'icon   '
        '.      '
        'text   '
        '.      '
        'buttons';

    &__icon {
        grid-area: icon;
        width: min(100%, 80px);
    }

    &__text {
        grid-area: text;
    }

    &__buttons {
        grid-area: buttons;
    }
}
</style>

export const USER_GROUPS = {
    GUEST: 'guests',
} as const

export const PAGE = {
    LOGIN: 'login',
    NUDGE_REGISTER: 'nudge-register',
    GAMES: 'games',
    HELP: 'help',
    IMPRINT: 'impressum',
    PRIVACY_POLICY: 'privacy-policy',
    PROFILE: 'profile',
    ACHIEVEMENTS: 'achievements',
    REWARDS: 'rewards',
    REWARD_DETAIL: 'reward-detail',
    QRHUNT_INDEX: 'qrhunt',
} as const

export const ROUTE_TARGET = {
    PARENT: Symbol.for('ROUTE_TO_PARENT'),
    PARENT_PARENT: Symbol.for('ROUTE_TO_PARENT_PARENT'),
    BACK: Symbol.for('ROUTE_BACK'),
    QRHUNT_BACK: Symbol.for('QRHUNT_BACK'),
} as const

export const UI_UNLOCK_TIMEOUT = 3000

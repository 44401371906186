import { cloneDeep } from 'lodash'

const log = createLog('app:pinia:content')

type RootState = {
    uid: UUID | null
    attributes: Attributes | null
    content: ComponentBuilderComponents[]
}

export const useContentStore = defineStore('content', () => {
    const websocketDispatcher = useWebsocketDispatcher()
    const qrHuntStore = useQRHuntStore()

    // #region STATE

    const initialState: RootState = {
        uid: null,
        attributes: null,
        content: [],
    }

    const state = reactive<RootState>(cloneDeep(initialState))

    // #endregion

    // #region WATCHERS
    watch(websocketDispatcher.jsonData, (jsonData) => {
        const data = toRaw(jsonData)

        handleMessage<ContentResponse>(ContentResponseSchema, data, (data) => {
            state.uid = data.content.uid
            state.attributes = data.content.attributes
            state.content = data.content.content
            log.debug('updated')
        })
    })

    // #endregion

    // #region GETTERS

    const getContent = computed<ComponentBuilderComponents[]>(() => state.content)

    const getRunningCheckpoint = computed(() => {
        const runningRoute = qrHuntStore.getRunningRoute

        if (!runningRoute) {
            return null
        }

        return runningRoute.checkpoints.filter((checkpoint) => !checkpoint.finished).find(Boolean)
    })

    // #endregion

    // #region ACTIONS

    function $reset() {
        Object.assign(state, cloneDeep(initialState))
    }

    function actionAcknowledgeContent(contentUid: UUID): void {
        const message: AcknowledgeContentRequest = {
            target: 'content',
            content: {
                type: 'acknowledgeContent',
                uid: contentUid,
            },
        }

        websocketDispatcher.send(message)
    }

    // #endregion

    return {
        ...toRefs(state),
        getContent,
        getRunningCheckpoint,
        actionAcknowledgeContent,
        $reset,
    }
})

// HMR
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useContentStore, import.meta.hot))
}

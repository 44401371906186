import { z } from 'zod'

export const AchievementSchema = z.object({
    uid: UUIDSchema,
    name: z.string(),
    description: z.string(),
    icon: z.string(),
    color: z.string(),
})

export const AchievementRequestSchema = z.object({
    target: z.literal('achievement_service'),
    content: z.object({
        type: z.literal('getAchievements'),
        activity: z.literal('qrhunt'),
    }),
})

export const AchievementResponseSchema = z.array(AchievementSchema)

export type Achievement = z.infer<typeof AchievementSchema>
export type AchievementRequest = z.infer<typeof AchievementRequestSchema>
export type AchievementResponse = z.infer<typeof AchievementResponseSchema>

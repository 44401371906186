export default defineNuxtPlugin((nuxtApp) => {
    const uiTextStore = useUiTexts()

    return {
        provide: {
            t: uiTextStore.t,
            tExists: uiTextStore.exists,
            e: uiTextStore.e,
        },
    }
})

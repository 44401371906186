<script setup lang="ts">
import { cloneDeep } from 'lodash'
import LogoSVG from '~/assets/logo/logo.svg?url'
import LogoFaceBookSVG from '~/assets/logo/logo-fb.svg?url'
import { useToast } from 'vue-toastification'

useSeoMeta({
    description: 'Happy Franky App',
    ogTitle: 'Happy Franky App',
    ogDescription: 'Begleitapp vor Ort',
    ogImage: LogoFaceBookSVG,
    ogImageWidth: 1200,
    ogImageHeight: 627,
    ogUrl: '[og:url]',
    twitterTitle: 'Happy Franky App',
    twitterDescription: 'Begleitapp vor Ort',
    twitterImage: LogoSVG,
    twitterCard: 'summary',
})
const debugDisplay = ref(false)

const activitySessionManager = useActivitySessionManagerStore()
const achievementsStore = useAchievementsStore()
const accountStore = useUserStatsStore()
const rewardsStore = useRewardsStore()
const userStore = useUserStore()
const qrhuntStore = useQRHuntStore()
const headerStore = useHeaderStore()
const userStatsStore = useUserStatsStore()
const contentStore = useContentStore()

// @ts-ignore
window.qrHuntRouter = useQRHuntRouter()
// @ts-ignore
window.contentStore = contentStore
// @ts-ignore
window.showError = showError
// @ts-ignore
window.userStatsStore = userStatsStore
// @ts-ignore
window.headerStore = headerStore
// @ts-ignore
window.debugDisplay = debugDisplay
// @ts-ignore
window.qrhuntStore = qrhuntStore
// @ts-ignore
window.toast = useToast()
// @ts-ignore
window.ref = ref
// @ts-ignore
window.until = until
// @ts-ignore
window.runtimeConfig = useRuntimeConfig()
// @ts-ignore
window.auth = useAuth()
// @ts-ignore
window.activitySessionManager = activitySessionManager
// @ts-ignore
window.accountStore = accountStore
// @ts-ignore
window.achievementsStore = achievementsStore
// @ts-ignore
window.rewardsStore = rewardsStore
// @ts-ignore
window.wait = wait
// @ts-ignore
window.router = useRouter()
// @ts-ignore
window.watch = watch
// @ts-ignore
window.useWebsocketDispatcher = useWebsocketDispatcher
// @ts-ignore
window.userStore = userStore
// @ts-ignore
window.useWebsocket = useWebSocket
// @ts-ignore
window.useFetch = useFetch
// @ts-ignore
window.toRaw = toRaw
// @ts-ignore
window.cloneDeep = cloneDeep
// @ts-ignore
window.reactive = reactive
// @ts-ignore
window.unref = unref
// @ts-ignore
window.toValue = toValue
// @ts-ignore
window.isProxy = isProxy

// const headers = useRequestHeaders(['cookie']) as HeadersInit
// const { data: token } = await useFetch('/api/token', { headers })
// const { data: jwt } = await useFetch('/api/jwt', { headers })
</script>

<template>
    <div id="app">
        <TheProgressBar />
        <!-- <NuxtLoadingIndicator /> -->
        <DebugDisplay v-if="debugDisplay" />
        <div class="layout-wrapper">
            <NuxtLayout :key="$route.fullPath">
                <div class="page-wrapper">
                    <NuxtPage />
                </div>
            </NuxtLayout>
        </div>
    </div>
</template>

<style lang="scss">
.debug-display {
    position: fixed;
    top: 0;
    right: 0;
}

.layout-wrapper {
    height: 100%;
}
.page-wrapper {
    height: 100%;
}

.layout-enter-active,
.layout-leave-active {
    transition: all 0.4s;
}

.layout-enter-from,
.layout-leave-to {
    opacity: 0;
    filter: blur(1rem);
}

.page-enter-active,
.page-leave-active {
    transition: all 0.4s;
}

.page-enter-from,
.page-leave-to {
    opacity: 0;
    filter: blur(1rem);
}
</style>

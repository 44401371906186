import { z } from 'zod'

// #region PRIMITIVES

export const UUIDSchema = z.string().uuid()
export const LiteralSchema = z.union([z.string(), z.number(), z.boolean(), z.null()])
export const AttributesSchema = z.record(z.string(), LiteralSchema)
export const ParamsSchema = z.record(z.string(), LiteralSchema)

export const JsonSchema: z.ZodType<Json> = z.lazy(() =>
    z.union([LiteralSchema, z.array(JsonSchema), z.record(JsonSchema)]),
)
export type UUID = z.infer<typeof UUIDSchema>
export type Literal = z.infer<typeof LiteralSchema>
export type Attributes = z.infer<typeof AttributesSchema>
export type Params = z.infer<typeof ParamsSchema>
export type Json = Literal | { [key: string]: Json } | Json[]

// #endregion

//#region ERRORS

export const ErrorSchema = z.object({
    type: z.literal('error'),
    content: z.object({
        errorType: z.string(),
        message: z.string().optional(),
    }),
})

export const ResourceNotFoundErrorSchema = ErrorSchema.extend({
    content: ErrorSchema.shape.content.extend({
        errorType: z.literal('resourceNotFound'),
    }),
})

export const UnauthenticatedErrorSchema = ErrorSchema.extend({
    content: ErrorSchema.shape.content.extend({
        errorType: z.literal('unauthenticated'),
    }),
})

export const InternalServerErrorSchema = ErrorSchema.extend({
    content: ErrorSchema.shape.content.extend({
        errorType: z.literal('internalServerError'),
    }),
})

export const WrongMessageTypeErrorSchema = ErrorSchema.extend({
    content: ErrorSchema.shape.content.extend({
        errorType: z.literal('wrongMessageType'),
    }),
})

export const NoRunningSessionErrorSchema = ErrorSchema.extend({
    content: ErrorSchema.shape.content.extend({
        errorType: z.literal('noRunningSession'),
    }),
})

export const InvalidContentTypeErrorSchema = ErrorSchema.extend({
    content: ErrorSchema.shape.content.extend({
        errorType: z.literal('invalidContentType'),
    }),
})

export const InvalidContentFormatSchema = ErrorSchema.extend({
    content: ErrorSchema.shape.content.extend({
        errorType: z.literal('invalidContentFormat'),
    }),
})

export type WsError = z.infer<typeof ErrorSchema>
export type InvalidContentFormat = z.infer<typeof InvalidContentFormatSchema>
export type InvalidContentTypeError = z.infer<typeof InvalidContentTypeErrorSchema>
export type ResourceNotFoundError = z.infer<typeof ResourceNotFoundErrorSchema>
export type WrongMessageTypeError = z.infer<typeof WrongMessageTypeErrorSchema>
export type UnauthenticatedError = z.infer<typeof UnauthenticatedErrorSchema>
export type InternalServerError = z.infer<typeof InternalServerErrorSchema>
// #endregion

// #region REQUEST/RESPONSE

export const RequestMessageSchema = z.object({
    target: z.string(),
    content: JsonSchema,
})

export const ResponseMessageSchema = z.object({
    type: z.string(),
    content: JsonSchema,
})

export type RequestMessage = z.infer<typeof RequestMessageSchema>
export type ResponseMessage = z.infer<typeof ResponseMessageSchema>

export const AuthenticationResponseMessageSchema = z.object({
    type: z.literal('authentication'),
    success: z.boolean(),
})

export type AuthenticationResponseMessage = z.infer<typeof AuthenticationResponseMessageSchema>

// #endregion

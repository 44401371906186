import { z } from 'zod'

// #region PRIMITIVES

export const RouteStateSchema = z.enum(['running', 'paused', 'aborted', 'finished']).nullable()
export const QRcodeCodeSchema = z.number().int()

export type QrCodeCode = z.infer<typeof QRcodeCodeSchema>
// #endregion

// #region RESOURCE

export const QRCodeSchema = z.object({
    resourceType: z.literal('qrCode').optional(),
    uid: UUIDSchema,
    code: QRcodeCodeSchema,
})

export const ActivitySchema = z.object({
    resourceType: z.literal('activity').optional(),
    uid: UUIDSchema,
    name: z.string(),
    params: ParamsSchema,
})

export const CheckpointSchema = z.object({
    resourceType: z.literal('checkpoint').optional(),
    uid: UUIDSchema,
    activity: ActivitySchema,
    attributes: AttributesSchema.nullable(),
    finished: z.boolean(),
    order: z.number(),
    qrCode: QRCodeSchema,
    routeUid: UUIDSchema,
})

export const RouteSchema = z.object({
    resourceType: z.literal('route').optional(),
    uid: UUIDSchema,
    // state: RouteStateSchema,
    attributes: AttributesSchema.nullable(),
    checkpoints: z.array(CheckpointSchema),
    session: z
        .object({
            lastFinishedCheckpoint: z
                .object({
                    activity: ActivitySchema,
                    attributes: AttributesSchema.nullable(),
                    finished: z.boolean(),
                    order: z.number(),
                    qrCode: QRCodeSchema,
                    routeUid: UUIDSchema,
                    uid: UUIDSchema,
                })
                .nullable(),
            routeUid: UUIDSchema,
            state: RouteStateSchema,
            uid: UUIDSchema,
            userUid: UUIDSchema,
        })
        .nullable(),
})

// #endregion

// #region REQUEST/RESPONSE

export const GetRouteByUidRequestSchema = z.object({
    target: z.literal('qrhunt'),
    content: z.object({
        type: z.literal('getRouteByUid'),
        uid: UUIDSchema,
    }),
})

export const GetRoutesRequestSchema = z.object({
    target: z.literal('qrhunt'),
    content: z.object({
        type: z.literal('getRoutes'),
    }),
})

export const RedeemQRCodeRequestSchema = z.object({
    target: z.literal('qrhunt'),
    content: z.object({
        type: z.literal('redeemCode'),
        code: QRcodeCodeSchema,
    }),
})

export const RouteResponseSchema = z.object({
    type: z.literal('resource'),
    content: RouteSchema,
})

export const RoutesResponseSchema = z.object({
    type: z.literal('resources'),
    content: z.object({
        resourceType: z.literal('route'),
        resources: z.array(RouteSchema),
    }),
})
window.RoutesResponseSchema = RoutesResponseSchema

export const CheckpointFinishedResponseSchema = z.object({
    type: z.literal('checkpointFinished'),
    content: z.object({
        route: RouteSchema,
        checkpoint: CheckpointSchema,
    }),
})

// #endregion

// #region ERRORS

export const WrongCheckpointOrderErrorSchema = ErrorSchema.extend({
    content: ErrorSchema.shape.content.extend({
        errorType: z.literal('wrongCheckpointOrder'),
    }),
})

// @ts-ignore
window.WrongCheckpointOrderErrorSchema = WrongCheckpointOrderErrorSchema

export const RouteAlreadyFinishedErrorSchema = ErrorSchema.extend({
    content: ErrorSchema.shape.content.extend({
        errorType: z.literal('routeAlreadyFinished'),
    }),
})

export const CheckpointAlreadyCompletedErrorSchema = ErrorSchema.extend({
    content: ErrorSchema.shape.content.extend({
        errorType: z.literal('checkpointAlreadyCompleted'),
    }),
})

export const QrCodeDoesNotBelongToRouteErrorSchema = ErrorSchema.extend({
    content: ErrorSchema.shape.content.extend({
        errorType: z.literal('qrCodeDoesNotBelongToRoute'),
        relatedRouteUids: z.array(UUIDSchema).nullable(),
    }),
})

// #endregion

// #region TYPES

export type RouteState = z.infer<typeof RouteStateSchema>
export type QRCode = z.infer<typeof QRCodeSchema>
export type Activity = z.infer<typeof ActivitySchema>
export type Checkpoint = z.infer<typeof CheckpointSchema>
export type Route = z.infer<typeof RouteSchema>
export type CheckpointFinishedResponse = z.infer<typeof CheckpointFinishedResponseSchema>
export type GetRouteByUidRequest = z.infer<typeof GetRouteByUidRequestSchema>
export type RouteResponse = z.infer<typeof RouteResponseSchema>
export type GetRoutesRequest = z.infer<typeof GetRoutesRequestSchema>
export type RoutesResponse = z.infer<typeof RoutesResponseSchema>
export type RedeemQRCodeRequest = z.infer<typeof RedeemQRCodeRequestSchema>
export type WrongCheckpointOrderError = z.infer<typeof WrongCheckpointOrderErrorSchema>
export type CheckpointAlreadyCompletedError = z.infer<typeof CheckpointAlreadyCompletedErrorSchema>
export type QrCodeDoesNotBelongToRouteError = z.infer<typeof QrCodeDoesNotBelongToRouteErrorSchema>
export type RouteAlreadyFinishedError = z.infer<typeof RouteAlreadyFinishedErrorSchema>

export type TransformedCheckpoint = {
    uid: UUID
    routeUid: UUID
    qrCodeUid: UUID
    qrCodeCode: number
    contentUid: UUID
    finished: boolean
    current: boolean
    name: string
    description: string
    order: number
}

export type TransformedRoute = {
    uid: string
    sessionUid?: string
    state?: RouteState
    icon: string
    color: string
    name: string
    description: string
    instructions?: string
    pointValue: number
    coins: number
    points: number
    stairs: number
    co2: number
    trees: number
    power: number
    checkpoints: TransformedCheckpoint[]
}

// #endregion

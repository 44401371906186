import { z } from 'zod'

export const RewardSchema = z.object({
    uid: UUIDSchema,
    name: z.string(),
    description: z.string(),
    image: z.string().nullish(),
    redeemUrl: z.string().url(),
    priceInCoins: z.number().nullable(),
    redeems: z.number().int(),
})

export const RewardsRequestSchema = z.object({
    target: z.literal('rewards'),
    content: z.object({
        type: z.literal('getRewards'),
    }),
})

export const RewardsResponseSchema = z.array(RewardSchema)

export type Reward = z.infer<typeof RewardSchema>
export type RewardsRequest = z.infer<typeof RewardsRequestSchema>
export type RewardsResponse = z.infer<typeof RewardsResponseSchema>

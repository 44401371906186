import { cloneDeep } from 'lodash'

const log = createLog('app:pinia:userStats')

type RootState = UserStats

export const useUserStatsStore = defineStore('userStats', () => {
    const websocketDispatcher = useWebsocketDispatcher()

    // #region STATE
    const initialState: RootState = {
        uid: null,
        coins: 0,
        points: 0,
        stairs: 0,
        power: 0,
        co2: 0,
        trees: 0,
    }

    const state = reactive<RootState>(cloneDeep(initialState))

    // #endregion

    // #region STATE UPDATES

    watch(websocketDispatcher.jsonData, (jsonData) => {
        const data = toRaw(jsonData)

        handleMessage<UserStatsResponse>(UserStatsResponseSchema, data, (data) => {
            Object.assign(state, data.content)
            log.debug('updated')
        })
    })

    // #endregion

    // #region ACTIONS

    function $reset(): void {
        Object.assign(state, cloneDeep(initialState))
    }

    function actionGetUserStats(): void {
        const message: UserStatsRequest = {
            target: 'user_stats',
            content: {
                type: 'readByUserUid',
            },
        }

        websocketDispatcher.send(message)
    }
    // #endregion

    return {
        ...toRefs(state),
        actionGetUserStats,
        $reset,
    }
})

// HMR
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useUserStatsStore, import.meta.hot))
}

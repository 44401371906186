<script lang="ts" setup>
const UNFINISHED_PROGRESS_MAX = 95 // percent
const TRICKLE_DURATION = 250 // milliseconds

const nuxtApp = useNuxtApp()

const progress = ref<number | null>(null)

let trickleTimeout: ReturnType<typeof setTimeout>
function trickle(): void {
    if (progress.value === null) {
        return
    }

    progress.value += (UNFINISHED_PROGRESS_MAX + 0.1 - progress.value) / 2.5

    if (progress.value >= UNFINISHED_PROGRESS_MAX) {
        progress.value = UNFINISHED_PROGRESS_MAX
    } else {
        trickleTimeout = setTimeout(
            trickle,
            TRICKLE_DURATION * 2 + (Math.random() - 0.5) * TRICKLE_DURATION,
        )
    }
}

nuxtApp.hook('page:start', () => {
    progress.value = 0
    trickle()
})
nuxtApp.hook('page:finish', () => {
    if (progress.value === null) {
        return
    }
    clearTimeout(trickleTimeout)
    progress.value = 100
    setTimeout(() => {
        if (progress.value === 100) {
            progress.value = null
        }
    }, TRICKLE_DURATION)
})
</script>

<template>
    <div class="the-progress-bar">
        <Transition name="the-progress-bar__progress-">
            <div
                v-if="progress !== null"
                class="the-progress-bar__progress"
            />
        </Transition>
    </div>
</template>

<style lang="scss">
.the-progress-bar {
    position: fixed;
    inset: 0;
    bottom: auto;
    z-index: 999999;
    overflow: hidden;
    user-select: none;
    pointer-events: none;

    &__progress {
        height: 5px;
        background-image: linear-gradient(270deg, #00828c, #339ba3, #64b4b9, #99cdd1, #cae5e7);
        transform-origin: left;
        transform: translateX(v-bind('`${-100 + (progress || 100)}%`'));
        transition:
            transform v-bind('`${TRICKLE_DURATION}ms`'),
            opacity v-bind('`${TRICKLE_DURATION}ms`');

        &--enter-from {
            transform: translateX(-100%);
        }

        &--leave-to {
            opacity: 0;
        }
    }
}
</style>

import { cloneDeep, get } from 'lodash'
import {
    GetSessionResponseSchema,
    GetSessionsResponseSchema,
    type StartSessionRequest,
    type GetSessionByUidRequest,
    type GetSessionsRequest,
    type Session,
    type SessionChangeResponse,
    type SessionChangeRequest,
    type UUID,
    type GetSessionsResponse,
    type SessionState,
    GetSessionResponse,
} from '~/utils'

const log = createLog('app:pinia:am')

type RootState = {
    sessions: Session[]
}

export const useActivitySessionManagerStore = defineStore('activitySessionManager', () => {
    const websocketDispatcher = useWebsocketDispatcher()
    const { $toast } = useNuxtApp()

    // #region STATE

    const initialState: RootState = {
        sessions: [],
    }

    const state = reactive<RootState>(cloneDeep(initialState))

    // #endregion

    // #region WATCHERS

    watch(websocketDispatcher.jsonData, (jsonData) => {
        const data = toRaw(jsonData)

        // prettier-ignore
        handleMessage<GetSessionsResponse>(GetSessionsResponseSchema, data, (data) => {
            state.sessions = data.content.resources
            log.debug('GetSessionsResponseSchema updated all sessions')
        })
        ||
        handleMessage<GetSessionResponse>(GetSessionResponseSchema, data, (data) => {
            const newSession = <Session>data.content
            const idx = state.sessions.findIndex(({ uid }) => uid === newSession.uid)

            if (idx !== -1) {
                if (newSession.state === 'aborted') {
                    state.sessions.splice(idx, 1)
                    log.debug(`remove session: ${newSession.attributes?.name}`)
                } else {
                    state.sessions.splice(idx, 1, newSession)
                    log.debug(`updated session: ${newSession.attributes?.name}`)
                }
            } else {
                state.sessions.push(newSession)
                log.debug(`added session: ${newSession.attributes?.name}`)
            }
        })
        ||
        handleMessage<SessionChangeResponse>(SessionChangeResponseSchema, data, (data) => {
            const newSession: Session = data.content
            // const session = findSessionByUid(newSession.uid)
            const idx = state.sessions.findIndex(({ uid }) => uid === newSession.uid)
            if (idx !== -1) {
                if (newSession.state === 'aborted') {
                    state.sessions.splice(idx, 1)
                    log.debug(`remove session: ${newSession.attributes?.name}`)
                } else {
                    state.sessions.splice(idx, 1, newSession)
                    log.debug(`updated session: ${newSession.attributes?.name}`)
                }
            } else {
                state.sessions.push(newSession)
                log.debug(`added session: ${newSession.attributes?.name}`)
            }
        })
    })
    // #endregion

    // #region GETTERS

    function activeSession(session: Session) {
        return session.state !== 'aborted'
    }

    function filterSessionBy({
        sessions,
        uid,
        routeUid,
        contentUid,
        activity,
        states = ['finished', 'running', 'paused'],
    }: {
        sessions?: Session[]
        uid?: UUID
        routeUid?: UUID
        contentUid?: UUID
        activity?: string
        states: SessionState[] | null | undefined
    }): Session[] {
        return sessions
            ? sessions
            : state.sessions
                  .map(toRaw)
                  .filter((session) => (states ? states.includes(session.state) : true))
                  .filter((session) => (activity ? session.activity === activity : true))
                  .filter((session) =>
                      routeUid ? get(session, 'params.routeUid') === routeUid : true,
                  )
                  .filter((session) =>
                      contentUid ? get(session, 'params.contentUid') === contentUid : true,
                  )
                  .filter((session) => (uid ? session.uid === uid : true))
    }

    function filterSessionByRouteUid(
        routeUid: UUID,
        states: SessionState[] | null | undefined = ['finished', 'running', 'paused'],
    ): Session[] {
        return filterSessionBy({
            routeUid,
            states,
            activity: 'qrhunt',
        })
    }

    function findSessionByRouteUid(
        routeUid: UUID,
        activity?: string,
        states: SessionState[] | null | undefined = ['finished', 'running', 'paused'],
    ): Session | undefined {
        return filterSessionBy({
            routeUid,
            states,
            activity,
        }).find(Boolean)
    }

    function filterSessionByUid(
        uid: UUID,
        states: SessionState[] | null | undefined = ['finished', 'running', 'paused'],
    ): Session[] {
        // TODO: check usage if activity should be narrowed to qrhunt
        return filterSessionBy({
            uid,
            states,
        })
    }

    function findSessionByUid(
        sessionUid: UUID,
        states: SessionState[] | null | undefined = ['finished', 'running', 'paused'],
    ): Session | undefined {
        return filterSessionByUid(sessionUid, states).find(Boolean)
    }

    const getSessions = computed(() =>
        state.sessions.filter((session) => session.state !== 'aborted'),
    )

    const getRunningQRHuntSession = computed(() => {
        return filterSessionBy({
            activity: 'qrhunt',
            states: ['running'],
        }).find(Boolean)
    })
    // #endregion

    // #region ACTIONS
    function $reset(): void {
        Object.assign(state, cloneDeep(initialState))
    }

    async function actionGetSessions(): Promise<void> {
        const message: GetSessionsRequest = {
            target: 'activity_session_manager',
            content: {
                type: 'getSessions',
                activity: 'qrhunt',
                parent: null,
                sessionStates: ['finished', 'paused', 'running'],
            },
        }

        websocketDispatcher.send(message)
    }

    function actionGetSessionByUId(sessionUid: UUID): void {
        const message: GetSessionByUidRequest = {
            target: 'activity_session_manager',
            content: {
                type: 'getSessionByUid',
                uid: sessionUid,
            },
        }

        websocketDispatcher.send(message)
    }

    function actionStartSession(routeUid?: UUID): void {
        if (!routeUid) {
            // $toast.error(`[am](start) no routeUid`)
            return
        }

        const message: StartSessionRequest = {
            target: 'activity_session_manager',
            content: {
                type: 'startSession',
                activity: 'qrhunt',
                params: {
                    routeUid: routeUid,
                },
            },
        }

        websocketDispatcher.send(message)
    }

    function actionPauseSession(sessionUid?: UUID): void {
        if (!sessionUid) {
            // $toast.error(`[am](pause) no sessionUid`)
            return
        }

        const message: SessionChangeRequest = {
            target: 'activity_session_manager',
            content: {
                type: 'changeSessionState',
                action: 'pause',
                activity: 'qrhunt',
                sessionUid: sessionUid,
            },
        }

        websocketDispatcher.send(message)
    }

    function actionResumeSession(sessionUid?: UUID): void {
        if (!sessionUid) {
            // $toast.error(`[am](resume) no sessionUid`)
            return
        }

        const message: SessionChangeRequest = {
            target: 'activity_session_manager',
            content: {
                type: 'changeSessionState',
                action: 'resume',
                activity: 'qrhunt',
                sessionUid: sessionUid,
            },
        }

        websocketDispatcher.send(message)
    }

    function actionAbortSession(sessionUid?: UUID): void {
        if (!sessionUid) {
            // $toast.error(`[am](abort) no sessionUid`)
            return
        }

        const message: SessionChangeRequest = {
            target: 'activity_session_manager',
            content: {
                type: 'changeSessionState',
                action: 'abort',
                activity: 'qrhunt',
                sessionUid: sessionUid,
            },
        }

        websocketDispatcher.send(message)
    }

    function actionFinishSession(sessionUid?: UUID): void {
        // $toast.warning('[am](finish) handled by backend')
        // if (!sessionUid) {
        //     $toast.error(`[am](finish) no sessionUid`)
        //     return
        // }
        // const message: SessionChangeRequest = {
        //     target: 'activity_session_manager',
        //     content: {
        //         type: 'changeSessionState',
        //         action: 'abort',
        //         activity: 'qrhunt',
        //         sessionUid: sessionUid,
        //     },
        // }
        // websocketDispatcher.send(message)
    }

    // #endregion

    return {
        ...toRefs(state),
        getSessions,
        getRunningQRHuntSession,
        filterSessionBy,
        filterSessionByUid,
        filterSessionByRouteUid,

        findSessionByUid,
        findSessionByRouteUid,

        actionGetSessions,
        actionGetSessionByUId,
        actionStartSession,
        actionPauseSession,
        actionResumeSession,
        actionAbortSession,
        actionFinishSession,
        $reset,
    }
})

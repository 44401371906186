const qrCodeValue = ref()
const qrCode = computed({
    get() {
        if (typeof qrCode.value == null) {
            return null
        }
        return Number(qrCodeValue.value) || null
    },
    set(newValue) {
        qrCodeValue.value = newValue
    },
})

export function useQRCode() {
    return {
        qrCode,
    }
}

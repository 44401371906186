import { default as indexOXkmHigKU1Meta } from "/app/src/pages/achievements/index.vue?macro=true";
import { default as indexkeNSVYatIaMeta } from "/app/src/pages/games/index.vue?macro=true";
import { default as qr_45huntSZAaRODKJAMeta } from "/app/src/pages/games/qr-hunt.vue?macro=true";
import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as nudge_45registerOwHTfQM8D8Meta } from "/app/src/pages/nudge-register.vue?macro=true";
import { default as indexSJNeMkG0qzMeta } from "/app/src/pages/profile/index.vue?macro=true";
import { default as helpASKe2VPIkVMeta } from "/app/src/pages/public/help.vue?macro=true";
import { default as imprintxRU334wLdBMeta } from "/app/src/pages/public/imprint.vue?macro=true";
import { default as privacy_45policy0nN54iC0uOMeta } from "/app/src/pages/public/privacy-policy.vue?macro=true";
import { default as _91uid_93Nwg1tq6LARMeta } from "/app/src/pages/rewards/[uid].vue?macro=true";
import { default as indexGMGjfk9KEFMeta } from "/app/src/pages/rewards/index.vue?macro=true";
export default [
  {
    name: indexOXkmHigKU1Meta?.name ?? "achievements",
    path: indexOXkmHigKU1Meta?.path ?? "/achievements",
    meta: indexOXkmHigKU1Meta || {},
    alias: indexOXkmHigKU1Meta?.alias || [],
    redirect: indexOXkmHigKU1Meta?.redirect || undefined,
    component: () => import("/app/src/pages/achievements/index.vue").then(m => m.default || m)
  },
  {
    name: indexkeNSVYatIaMeta?.name ?? "games",
    path: indexkeNSVYatIaMeta?.path ?? "/games",
    meta: indexkeNSVYatIaMeta || {},
    alias: indexkeNSVYatIaMeta?.alias || [],
    redirect: indexkeNSVYatIaMeta?.redirect || undefined,
    component: () => import("/app/src/pages/games/index.vue").then(m => m.default || m)
  },
  {
    name: qr_45huntSZAaRODKJAMeta?.name ?? "games-qr-hunt",
    path: qr_45huntSZAaRODKJAMeta?.path ?? "/games/qr-hunt",
    meta: qr_45huntSZAaRODKJAMeta || {},
    alias: qr_45huntSZAaRODKJAMeta?.alias || [],
    redirect: qr_45huntSZAaRODKJAMeta?.redirect || undefined,
    component: () => import("/app/src/pages/games/qr-hunt.vue").then(m => m.default || m)
  },
  {
    name: indexdPwi6hPdMfMeta?.name ?? "index",
    path: indexdPwi6hPdMfMeta?.path ?? "/",
    meta: indexdPwi6hPdMfMeta || {},
    alias: indexdPwi6hPdMfMeta?.alias || [],
    redirect: indexdPwi6hPdMfMeta?.redirect || undefined,
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: nudge_45registerOwHTfQM8D8Meta?.name ?? "nudge-register",
    path: nudge_45registerOwHTfQM8D8Meta?.path ?? "/nudge-register",
    meta: nudge_45registerOwHTfQM8D8Meta || {},
    alias: nudge_45registerOwHTfQM8D8Meta?.alias || [],
    redirect: nudge_45registerOwHTfQM8D8Meta?.redirect || undefined,
    component: () => import("/app/src/pages/nudge-register.vue").then(m => m.default || m)
  },
  {
    name: indexSJNeMkG0qzMeta?.name ?? "profile",
    path: indexSJNeMkG0qzMeta?.path ?? "/profile",
    meta: indexSJNeMkG0qzMeta || {},
    alias: indexSJNeMkG0qzMeta?.alias || [],
    redirect: indexSJNeMkG0qzMeta?.redirect || undefined,
    component: () => import("/app/src/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: helpASKe2VPIkVMeta?.name ?? "public-help",
    path: helpASKe2VPIkVMeta?.path ?? "/public/help",
    meta: helpASKe2VPIkVMeta || {},
    alias: helpASKe2VPIkVMeta?.alias || [],
    redirect: helpASKe2VPIkVMeta?.redirect || undefined,
    component: () => import("/app/src/pages/public/help.vue").then(m => m.default || m)
  },
  {
    name: imprintxRU334wLdBMeta?.name ?? "public-imprint",
    path: imprintxRU334wLdBMeta?.path ?? "/public/imprint",
    meta: imprintxRU334wLdBMeta || {},
    alias: imprintxRU334wLdBMeta?.alias || [],
    redirect: imprintxRU334wLdBMeta?.redirect || undefined,
    component: () => import("/app/src/pages/public/imprint.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy0nN54iC0uOMeta?.name ?? "public-privacy-policy",
    path: privacy_45policy0nN54iC0uOMeta?.path ?? "/public/privacy-policy",
    meta: privacy_45policy0nN54iC0uOMeta || {},
    alias: privacy_45policy0nN54iC0uOMeta?.alias || [],
    redirect: privacy_45policy0nN54iC0uOMeta?.redirect || undefined,
    component: () => import("/app/src/pages/public/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: _91uid_93Nwg1tq6LARMeta?.name ?? "rewards-uid",
    path: _91uid_93Nwg1tq6LARMeta?.path ?? "/rewards/:uid()",
    meta: _91uid_93Nwg1tq6LARMeta || {},
    alias: _91uid_93Nwg1tq6LARMeta?.alias || [],
    redirect: _91uid_93Nwg1tq6LARMeta?.redirect || undefined,
    component: () => import("/app/src/pages/rewards/[uid].vue").then(m => m.default || m)
  },
  {
    name: indexGMGjfk9KEFMeta?.name ?? "rewards",
    path: indexGMGjfk9KEFMeta?.path ?? "/rewards",
    meta: indexGMGjfk9KEFMeta || {},
    alias: indexGMGjfk9KEFMeta?.alias || [],
    redirect: indexGMGjfk9KEFMeta?.redirect || undefined,
    component: () => import("/app/src/pages/rewards/index.vue").then(m => m.default || m)
  }
]
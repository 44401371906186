import { get } from 'lodash'

export const useUiTexts = defineStore('uiTexts', {
    state: () => ({
        page: {
            preLogin: {
                introText: `
                    <h1>Starte (d)eine Entdeckungs&shy;reise, die sich lohnt!</h1>
                `,
                buttons: {
                    keycloakLogin: 'mit HAPPY&nbsp;FRANKY anmelden',
                    guestLogin: 'Als Gast teilnehmen',
                },
            },
            postRegister: {
                introText: `
                    <h1>Hi {firstname}, willkommen im HAPPY&nbsp;FRANKY Troisdorf</h1>
                    <p>Dies ist deine digitale Home Base, in der spannende Spiele und Belohnungen auf dich warten. Zum Start kannst du mit dem Exploration Game im HAPPY&nbsp;FRANKY auf eine spannende Entdeckungsreise gehen. Halte die Augen offen: Weitere Routen, Features und Spiele fügen wir in regelmäßigen Abständen hinzu.</p>
                `,
                cards: {
                    headline: `Deine Inhalte`,
                    items: [
                        {
                            title: 'Exploration Game',
                            subTitle:
                                'Folge den Hinweisen und entdecke jeden Winkel des HAPPY&nbsp;FRANKY.',
                        },
                    ],
                },
            },
            queryAbilities: {
                introText: `
                    <h1>Bist du in der Lage Treppen zu steigen?</h1>
                    <p>Auf unseren Routen geht es treppauf, treppauf, von Ebene zu Ebene. Falls du aus gesundheitlichen Gründen keine Treppen steigen kannst, bieten wir dir alternative Routen an, die für dich leichter zu bewältigen sind.</p>
                `,
                buttonLabel: {
                    yes: 'Ja',
                    no: 'Nein',
                },
            },
            nudgeRegister: {
                introText: `
                    <h1>Bitte anmelden</h1>
                    <p>Um Zugriff auf ein Profil, Trophäen und Coupons zu erhalten, musst du dich zunächst registrieren.</p>
                `,
                buttonLabel: {
                    register: 'Jetzt registrieren',
                    continue: 'Weiter ohne Registrierung',
                },
            },
            qrhunt: {
                overline: `Exploration Game`,
                introText: `
                    <p class="small">Entdecke HAPPY&nbsp;FRANKY</p>
                    <h1>Das Exploration Game</h1>
                    <p>Wähle eine Route, entdecke HAPPY&nbsp;FACTS im gesamten HAPPY&nbsp;FRANKY und sammle dabei Punkte, Trophäen und Coupons. Sukzessive kannst du immer wieder andere Routen absolvieren, Neues entdecken und dabei etwas für dich und die Umwelt tun.</p>
                `,
                routes: {
                    headline: 'Routenauswahl',
                },
                noRoutes: `Oh! Wir stecken noch mitten in der Routenplanung. Schau bitte später noch mal vorbei.`,
                buttonLabel: {
                    qrCode: 'QR-Code scannen',
                    cancelRoute: 'Route abbrechen',
                    completeRoute: 'Route abschließen',
                    backHome: 'Zurück zur Startseite',
                },
            },
            achievements: {
                introText: `
                    <p class="small">HAPPY&nbsp;FRANKY Games</p>
                    <h1>Meine Trophäen</h1>
                `,
                noAchievements: `Keine Trophäen verfügbar`,
            },
            rewards: {
                introText: `
                    <p class="small">HAPPY&nbsp;FRANKY Coupons</p>
                    <h1>Meine Coupons</h1>
                `,
            },
            rewardDetail: {
                introText: `
                    <p class="small">Welcome Cupon</p>
                    <h1>{rewardName}</h1>
                `,
            },
            profile: {
                myAccount: 'Mein Account',
                accountInfo: {
                    headline: 'Mein Punktestand',
                },
            },
            routeScan: {
                introText: `
                    <p class="small">QR-Code</p>
                    <h1>{step.name}!</h1>
                    <p>Scanne den QR-Code, schließe den Routenpunkt ab und schalte dadurch einen HAPPY&nbsp;FACT frei.</p>
                    <br>
                    <h6>Tipp:</h6>
                    <div>{step.description}</div>`,
                manualText: `
                    <p class="small">Manuelle Eingabe</p>
                    <h1>{step.name}</h1>
                    <p>Falls du keine Kamera zur Verfügung hast, kannst du den Code auch manuell eingeben.
                    Dieser befindet sich unterhalb des QR-Codes.</p>
                    <br>
                    <h6>Tipp:</h6>
                    <div>{step.description}</div>
                `,
                manualInput: {
                    label: 'Code (4-stellige Zahl)',
                    placeholder: '0000',
                },
                buttons: {
                    submit: 'Absenden',
                },
                buttonLabel: 'Weiter',
            },
            routeScanFailure: {
                introText: `
                    <p class="small">Sorry</p>
                    <h1>Falscher QR-Code</h1>
                    <p>Dies war leider nicht der richtige QR-Code. Schau dich nochmal um und versuche den richtigen QR-Code zu finden.</p>
                `,
                buttonLabel: {
                    back: 'Zurück',
                },
            },
            routeFact: {
                buttonLabel: {
                    next: 'Weiter',
                },
            },
            routeFinished: {
                introText: `
                    <p class="small">{routeName}</p>
                    <h1>
                        Gratuliere, du hast die ´{routeName}´ absolviert!
                    </h1>
                `,
                buttonLabel: {
                    next: `zur Übersicht`,
                },
            },
            routeCancel: {
                introText: `
                    <p class="small">Bist du dir sicher?</p>
                    <h1>Route beenden</h1>
                    <p>Möchtest Du die Route wirklich beenden?Dadurch verlierst du jegliche Fortschritte die du bereits in der Route erzielt hast.</p>
                `,
                buttons: {
                    confirm: 'Ja',
                    cancel: 'Nein',
                },
            },
            routePause: {
                introText: `
                    <p class="small">Bist du dir sicher?</p>
                    <h1>Route pausieren</h1>
                    <p>Möchtest Du die Route wirklich pausieren? Du kannst jederzeit am gespeicherten Spielstand fortsetzen.</p>
                `,
                buttons: {
                    confirm: 'Ja',
                    cancel: 'Nein',
                },
            },
            routeCancelDialog: {
                introText: `
                    <p class="small">Bist du dir sicher?</p>
                    <h1>Route beenden</h1>
                    <p>Möchtest Du die Route wirklich beenden?Dadurch verlierst du jegliche Fortschritte, die du bereits in der Route erzielt hast.</p>
                `,
                buttons: {
                    confirmPause: 'Route pausieren',
                    confirmCancel: 'Route abbrechen',
                    cancel: 'zurück',
                },
            },
            imprint: {
                text: `
                    <h1>Impressum</h1>
                    <h2>Angaben gemäß § 5 TMG</h2>
                    <p>KC Prometheus GmbH<br>Leibnizstraße 49<br>10629 Berlin</p>
                    <p>Handelsregister: HRB 234251 B<br>Registergericht: AG Charlottenburg</p>
                    <p><strong>Vertreten durch:</strong><br>Hagen Kahmann</p>
                    <h2>Kontakt</h2>
                    <p>E-Mail: kontakt@happyfranky.de</p>
                    <h2>Redaktionell verantwortlich</h2>
                    <p>KC Prometheus GmbH<br>Leibnizstraße 49<br>10629 Berlin</p>
                    <h2>EU-Streitschlichtung</h2>
                    <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a>.<br>Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
                    <h2>Verbraucherstreitbeilegung/ Universalschlichtungsstelle</h2>
                    <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen</p>
                    <p></p>
                    <p>Quelle:https://www.e-recht24.de &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </p>
                `,
            },
            privacyPolicy: {
                text: `
                    <h1>Datenschutzerklärung</h1>
                    <h2>1. Datenschutz auf einen Blick</h2>
                    <h3>Allgemeine Hinweise</h3>
                    <p>Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Datenpassiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmenSie unserer unter diesem Text aufgeführten Datenschutzerklärung.</p>
                    <h3>Datenerfassung auf dieser Website</h3>
                    <h4>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</h4>
                    <p>Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.</p>
                    <h4>Wie erfassen wir Ihre Daten?</h4>
                    <p>Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein Kontaktformular eingeben.Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.</p>
                    <h4>Wofür nutzen wir Ihre Daten?</h4>
                    <p>Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. AndereDaten können zur Analyse Ihres Nutzerverhaltens verwendet werden.</p>
                    <h4>Welche Rechte haben Sie bezüglich Ihrer Daten?</h4>
                    <p>Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oderLöschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben,können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unterbestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.</p>
                    <p>Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.</p>
                    <h3>Analyse-Tools und Tools von Drittanbietern</h3>
                    <p>Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem mit sogenannten Analyseprogrammen.</p>
                    <p>Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der folgenden Datenschutzerklärung.</p>
                    <h2>2. Hosting</h2>
                    <p>Wir hosten die Inhalte unserer Website bei folgendem Anbieter:</p>
                    <p><strong>Webflow</strong></p>
                    <p>Anbieter ist die Webflow, Inc., 398 11th Street, 2nd Floor, San Francisco, CA 94103, USA (nachfolgend Webflow). Wenn Sie unsere Website besuchen, erfasst Webflow verschiedene Logfiles inklusive Ihrer IP-Adressen.</p>
                    <p>Webflow ist ein Tool zum Erstellen und zum Hosten von Websites. Webflow speichert Cookies oder sonstige Wiedererkennungstechnologien, die für die Darstellung der Seite, zur Bereitstellung bestimmter Webseitenfunktionen und zur Gewährleistung der Sicherheit erforderlich sind (notwendige Cookies).</p>
                    <p>Details entnehmen Sie der Datenschutzerklärung von Webflow:<br>https://webflow.com/legal/eu-privacy-policy.</p>
                    <p>Die Verwendung von Webflow erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben einberechtigtes Interesse an einer möglichst zuverlässigen Darstellung unserer Website. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art.6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder denZugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.</p>
                    <p>Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt.<br>Details finden Sie hier:<br>https://webflow.com/legal/eu-privacy-policy.</p>
                    <h2>3. Allgemeine Hinweise und Pflichtinformationen</h2>
                    <h3>Datenschutz</h3>
                    <p>Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.</p>
                    <p>Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben.<br>Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.</p>
                    <p>Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.</p>
                    <h4>Hinweis zur verantwortlichen Stelle</h4>
                    <p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
                    <p>KC Prometheus GmbH<br>Leibnizstraße 49<br>10629 Berlin<br>E-Mail: kontakt@happyfranky.de</p>
                    <p>Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.)entscheidet.</p>
                    <h4>Speicherdauer</h4>
                    <p>Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleibenIhre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie einberechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.</p>
                    <h4>Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website</h4>
                    <p>Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer ausdrücklichen Einwilligung in die Übertragung personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung außerdem auf Grundlage von Art.49 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen inIhr Endgerät (z. B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich auf Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zurVertragserfüllung oder zur Durchführung vor vertraglicher Maßnahmen erforderlich, verarbeiten wir IhreDaten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese zur Erfüllung einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO.Die Datenverarbeitung kann ferner auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. fDSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen Rechtsgrundlagen wird in den folgendenAbsätzen dieser Datenschutzerklärung informiert.</p>
                    <h4>Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten</h4>
                    <p>Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den USA oder sonstigen datenschutzrechtlich nicht sicheren Drittstaaten. Wenn diese Tools aktiv sind, können Ihrepersonenbezogene Daten in diese Drittstaaten übertragen und dort verarbeitet werden. Wir weisen daraufhin, dass in diesen Ländern kein mit der EU vergleichbares Datenschutzniveau garantiert werden kann. Beispielsweise sind US-Unternehmen dazu verpflichtet, personenbezogene Daten an Sicherheitsbehörden herauszugeben, ohne dass Sie als Betroffener hiergegen gerichtlich vorgehen könnten. Es kann daher nicht ausgeschlossen werden, dass US-Behörden (z. B. Geheimdienste) Ihre auf US-Servern befindlichen Daten zu Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern. Wir haben auf diese Verarbeitungstätigkeiten keinen Einfluss.</p>
                    <h4>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h4>
                    <p>Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgtenDatenverarbeitung bleibt vom Widerruf unberührt.</p>
                    <h4>Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)</h4>
                    <p>WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATENWIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).</p>
                    <p>WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATENANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).</p>
                    <h4>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h4>
                    <p>Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.</p>
                    <h4>Recht auf Datenübertragbarkeit</h4>
                    <p>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertragsautomatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>
                    <h4>Auskunft, Löschung und Berichtigung</h4>
                    <p>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden.</p>
                    <h4>Recht auf Einschränkung der Verarbeitung</h4>
                    <p>Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in folgenden Fällen:</p>
                    <p>- Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</p>
                    <p>- Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie statt der Löschung die Einschränkung der Datenverarbeitung verlangen.</p>
                    <p>- Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</p>
                    <p>- Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</p>
                    <p>-Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats verarbeitet werden.</p>
                    <h4>SSL- bzw. TLS-Verschlüsselung</h4>
                    <p>Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von„http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.</p>
                    <p>Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.</p>
                    <h2>4. Datenerfassung auf dieser Website</h2>
                    <h3>Cookies</h3>
                    <p>Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind kleine Datenpakete und richten auf Ihrem Endgerät keinen Schaden an. Sie werden entweder vorübergehend für die Dauer einer Sitzung (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endgerät gespeichert. Session-Cookies werden nach Ende Ihres Besuchs automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese selbst löschen oder eine automatische Löschung durch Ihren Webbrowser erfolgt.</p>
                    <p>Cookies können von uns (First-Party-Cookies) oder von Drittunternehmen stammen (sog. Third-Party-Cookies). Third-Party-Cookies ermöglichen die Einbindung bestimmter Dienstleistungen vonDrittunternehmen innerhalb von Webseiten (z. B. Cookies zur Abwicklung von Zahlungsdienstleistungen).</p>
                    <p>Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig, da bestimmte Webseitenfunktionen ohne diese nicht funktionieren würden (z. B. die Warenkorbfunktion oder die Anzeige von Videos). Andere Cookies können zur Auswertung des Nutzerverhaltens oder zu Werbezweckenv erwendet werden.</p>
                    <p>Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs, zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z. B. für die Warenkorbfunktion) oder zur Optimierung derWebsite (z. B. Cookies zur Messung des Webpublikums) erforderlich sind (notwendige Cookies), werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von notwendigen Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine Einwilligung zur Speicherung von Cookies und vergleichbaren Wiedererkennungstechnologien abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1TTDSG); die Einwilligung ist jederzeit widerrufbar.</p>
                    <p>Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.</p>
                    <p>Welche Cookies und Dienste auf dieser Website eingesetzt werden, können Sie der nachfolgenden Auflistung entnehmen.</p>
                `,
            },
            help: {
                text: `
                    <h1>Hilfe</h1>
                    <p>
                        So funktioniert das <strong>Exploration Game:</strong><br>
                        <br>
                        Du hast kein konkretes Ziel oder willst das HAPPY&nbsp;FRANKY mal auf
                        ganz andere Art und Weise erleben? Dann bist du beim Exploration
                        Game genau richtig. Anhand von verschiedenen Routen erkundest du
                        alle Ebenen des HAPPY&nbsp;FRANKY, sammelst Trophäen, Punkte und
                        Coins, die du später sogar gegen Coupons eintauschen kannst.
                    </p>
                    <h4>Routen</h4>
                    <p>
                        Die Routen sind das Herzstück des Exploration Games. Jeden Tag
                        kannst du eine von insgesamt 3 möglichen Routen absolvieren. Eine
                        Route besteht aus mehreren Punkten und kann in Umfang und
                        Schwierigkeit variieren. Wir werden in regelmäßigen Abständen neuer
                        Routen hinzufügen.
                    </p>
                    <h4>Hinweise</h4>
                    <p>
                        Jeder Punkt stellt sich im HAPPY&nbsp;FRANKY als QR-Code dar. Zu jedem
                        Routenpunkt erhältst du einen Hinweis, der dir verrät, wo du den QR-Code
                        suchen musst. Manchmal sind sie leicht zu finden, manchmal
                        nicht.
                    </p>
                    <h4>QR-Code scannen</h4>
                    <p>
                        Hast du den QR-Code entdeckt, musst du ihn scannen. Wenn es der
                        richtige Code ist, schaltet sich der Routenpunkt frei und es erscheint
                        ein HAPPY&nbsp;FACT. Komplettiere eine Route, indem du all ihre
                        zugehörigen QR-Codes findest und scannst. Erst dann schaltet sich
                        eine Belohnung frei. Du kannst eine Route auch pausieren und später
                        weiterspielen, wenn du das nächste Mal im HAPPY&nbsp;FRANKY bist.<br>
                        <br>
                        Ist die Route absolviert erhältst du Coins und Punkte als Belohnung.
                        WICHTIG: Das geht nur, wenn du auch ein Profil anlegst, da wir deine
                        Leistung sonst nicht speichern und dir zuordnen können.
                    </p>
                    <h4>Coins</h4>
                    <p>
                        Coins kannst du durch das Abschließen von Routen sammeln und
                        gegen Coupons eintauschen - oder für den guten Zweck spenden.
                    </p>
                    <h4>Punkte</h4>
                    <p>
                        Mit jeder Route, die du absolvierst, erarbeitest du dir Punkte. Je
                        intensiver du das HAPPY&nbsp;FRANKY erkundest, desto mehr Punkte
                        kannst du ergattern, und dich in den Bestenlisten vor deine Freunde
                        schieben.
                    </p>
                    <h4>Leistungen</h4>
                    <p>
                        Zu deinen Leistungen gehören die Treppenstufen, die du beim
                        absolvieren der Routen absolvierst und angelehnt daran, die so
                        eingesparte Energie und das CO2. Denn wenn du was für dich und die
                        Umwelt tust, können unsere Aufzüge stehenbleiben und verbrauchen
                        weniger Energie. Natürlich können wir das nicht auf die Wattstunde
                        genau berechnen, und arbeiten deshalb mit Mittel- und Schätzwerten.
                        In deinem Profil wachsen deine Leistungen mit jeder absolvierten
                        Route. Also, bleib dran.
                    </p>
                    <p>
                        Solltest du Fragen haben, wende dich gerne per Mail an: <a href="mailto:">TBD</a>
                    </p>
                `,
            },
            error: {
                code: {
                    default: {
                        subline: 'Sorry',
                        statusMessage: 'unbekannter Fehler',
                        description: `
                            <p>Du hast einen Bereich der App gefunden, von dem selbst wir nicht wussten, dass es ihn gibt. Das entspricht natürlich unserem Credo, dass du bei HAPPY&nbsp;FRANKY stets etwas Neues entdecken kannst. Aber nun kehre lieber zurück zum Home Screen. :)</p>
                            <br />
                            <br />
                            <p>Versuch die Seite neuzuladen oder drück auf den Button unten, um zum Home Screen zurückzukehren…</p>
                            <p>Wenn nichts hilft, kontaktiere unseren Support <a href="mailto:support@happy-franky.de">support@happy-franky.de</a></p>
                        `,
                    },
                },
                buttons: {
                    back: 'Zurück zum Home Screen',
                },
            },
        },
        kpiInfo: {
            headline: 'Erreichte Leistung',
            coinsLabel: 'Coins',
            coinsDescription:
                'Coins kannst du durch das Abschließen von Routen sammeln und gegen Coupons eintauschen - oder für den guten Zweck spenden.',
            scoreLabel: 'Punkte',
            scoreDescription:
                'Mit jeder Route, die du absolvierst, erarbeitest du dir Punkte. Je intensiver du das HAPPY&nbsp;FRANKY erkundest, desto mehr Punkte kannst du ergattern, und dich in den Bestenlisten vor deine Freunde schieben.',
            stairsLabel: 'Stufen',
            stairsDescription:
                'Hier zählen wir in etwa die Stufen, die du auf deinen Routen zurücklegst. (Natürlich stimmt das nur, wenn du auch etwas für dich sowie die Umwelt tust und unsere Treppenhäuser nutzt).',
            powerLabel: 'Strom',
            powerDescription: `Mit jeder Stufe, die du nimmst, sparst du Energie, die sonst unsere Aufzüge und Rolltreppen verbrauchen würden. Hier siehst du, wie viel Energie* du schon eingespart hast. Bleib dran!
                <br><br>
                (*theoretischer Schätzwert)`,
            co2Label: 'CO₂',
            co2Description: `Mit jeder Stufe, die du nimmst, sparst du Energie und das reduziert den Ausstoß von CO2. Hier siehst du, wie viel CO2* du schon eingespart hast. Weiter so!
                <br><br>
                (*theoretischer Schätzwert)`,
            treesLabel: 'Bäume',
            treesDescription:
                'Zukünftig wirst du deine Coins auch spenden und damit Aufforstungsprojekte in Südamerika unterstützen können. Hier siehst du, wie viele Bäume dank deiner Hilfe angepflanzt wurden. Danke!',
        },
        menu: {
            introText: `
                <p class="small">Navigation</p>
                <h1>Schau dich um</h1>
                <p>Von hier aus gelangst du schnell zur Startseite, kannst dich über unseren Datenschutz informieren oder in der Hilfe mehr Informationen zu unseren Games erhalten.</p>
            `,
            list: {
                headline: 'Hier geht´s lang',
                items: {
                    start: 'Start',
                    help: 'Hilfe',
                    imprint: 'Impressum',
                    privacyPolicy: 'Datenschutz',
                    logout: 'Ausloggen',
                },
            },
        },
        navbar: {
            profile: 'Profil',
            achievements: 'Trophäen',
            rewards: 'Coupons',
        },
        displayTile: {
            info: 'Info',
        },
        errors: {
            // genericError: `Huch da hat was nicht geklappt, versuchs später nochmal`,
            genericError: `Sorry.<br />
                404 - Damit haben wir nicht gerechnet!<br />
                Du hast einen Bereich der App gefunden, von dem selbst wir nicht wussten, dass es ihn gibt. Das entspricht natürlich unserem Credo, dass du bei HAPPY FRANKY stets etwas Neues entdecken kannst. Aber nun kehre lieber zurück zum Home Screen. :)`,

            get noRunningSession() {
                // userfixable: maybe
                // resolution: restart route
                return `Entschuldige. Hier gab es einen Session-Konflikt. Bitte starte die Route erneut.`
            },
            get contentNotFound() {
                // userfixable: maybe
                // resolution: reload app rescan qrcode
                return 'Moment.<br>Der Schritt wurde leider nicht richtig verbucht. Scanne den QR-Code bitte noch mal.'
            },
            get contentDoesNotMatch() {
                // userfixable: maybe
                // resolution: reload app, restart route, restart route
                return this.contentNotFound
            },

            get wrongCheckpointOrder() {
                // userfixable: yes
                // resolution: scan correct qrcode
                return `Moment.<br />Das ist nicht der richtige QR-Code. Scanne einen anderen.`
            },
            get checkpointAlreadyCompleted() {
                // userfixable: yes
                // resolution: scan correct qrcode
                return this.wrongCheckpointOrder
            },

            get qrCodeDoesNotBelongToRoute() {
                // userfixable: yes
                // resolution: scan correct qrcode
                return this.wrongCheckpointOrder
            },
            get routeAlreadyFinished() {
                // userfixable: yes
                // resolution: play another route if any left
                return `Moment.<br />Diese Route hast du bereits beendet. Starte eine andere Route.`
            },
            get invalidContentType() {
                // userfixable: no
                // resolution: contact app support
                return this.genericError
            },
            get resourceNotFound() {
                // userfixable: maybe
                // resolution: reload app, retry, contact app support
                return this.invalidContentType
            },
            get wrongMessageType() {
                // userfixable: no
                // resolution: contact app support
                return this.invalidContentType
            },
            get unauthenticated() {
                // userfixable: maybe
                // resolution: relogin
                return `Warte mal.<br>Du versuchst etwas zu tun, wofür du eingeloggt sein muss. Logge ich zuerst ein und versuche es noch mal.`
            },
            get internalServerError() {
                // userfixable: no
                // resolution: contact app support
                return this.invalidContentType
            },
            get invalidContentFormat() {
                // userfixable: no
                // resolution: contact app support
                return this.invalidContentType
            },
            get conflictingSessionState() {
                // userfixable: maybe
                // resolution: restart route, contact app support
                return `Moment.<br />Du kannst nur 3 Routen gleichzeitig gestartet haben.`
            },
            get refreshAccessTokenError() {
                // userfixable: no
                // resolution: relogin, reload app, blame the wlan/mobile network
                return `Oh, nein.<br />
                Keine Verbindung zum W-LAN/Mobilfunknetz.<br />
                Logge dich erneut ein.`
            },
            get cameraNotAllowedError() {
                // user denied camera access permisson
                // userfixable: yes
                // resolution: enable cam access
                return `Keine Kamera?<br>Du musst die Kamera in den Systemeinstellungen deines Handys freigeben.`
            },
            get cameraNotFoundError() {
                // no suitable camera device installed
                // userfixable: no
                // resolution: -
                return `Entschuldige.<br>Du hast gar keine Kamera, aber du kannst den Code manuell eingeben.`
            },
            get cameraNotSupportedError() {
                // page is not served over HTTPS (or localhost)
                // userfixable: maybe
                // resolution: use other device?
                return this.cameraNotFoundError
            },
            get cameraNotReadableError() {
                // maybe camera is already in use
                // userfixable: maybe
                // resolution: terminate other programs using cam
                return `"Achtung.<br>Wir können nicht auf deine Kamera zugreifen. Vielleicht greifen andere Applikation bereits auf sie zu."`
            },
            get cameraOverconstrainedError() {
                // did you requested the front camera although there is none?
                // userfixable: maybe
                // resolution: ?
                return `Entschuldige.<br>Es ist ein Fehler beim Zugriff auf die Kamera aufgetreten. Bitte gib den Code manuell ein.`
            },
            get cameraStreamApiNotSupportedError() {
                // browser seems to be lacking features
                // userfixable: maybe
                // resolution: use other browser
                return `"Hmm.<br>Dein Browser unterstützt unsere Anwendung nicht. Probiere einen anderen aus oder gib den Code manuell ein."`
            },
            get cameraUnknownError() {
                // userfixable: maybe
                // resolution: ?
                return `Sorry.<br />
                    404 - Damit haben wir nicht gerechnet!<br />
                    Du hast einen Bereich der App gefunden, von dem selbst wir nicht wussten, dass es ihn gibt.
                    Aber du kannst den Code manuell eingeben und weiterspielen.
                `
            },
        },
    }),
    getters: {
        exists(state) {
            return (key: string) => {
                return get(state, key, null) !== null
            }
        },
        t(state) {
            return (key: string, replaces: Record<string, string | null | undefined> = {}) => {
                let text: string = get(state, key)

                Object.entries(replaces).forEach(([key, value]) => {
                    text = text.replaceAll(key, value ?? '')
                })

                return text
            }
        },
        e(state) {
            return (key: string, replaces: Record<string, string | null | undefined> = {}) => {
                let text: string = get(state, `errors.${key}`, state.errors.genericError)

                Object.entries(replaces).forEach(([key, value]) => {
                    text = text.replaceAll(key, value ?? '')
                })

                return text
            }
        },
    },
})

// HMR
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useUiTexts, import.meta.hot))
}

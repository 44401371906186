import { invoke, type UntilToMatchOptions } from '@vueuse/core'
import { ZodSchema, z } from 'zod'

export default defineNuxtPlugin((nuxtApp) => {
    const websocketDispatcher = useWebsocketDispatcher()

    async function wsWaitFor(schema: ZodSchema, options: UntilToMatchOptions = {}) {
        type InferredSchemaType = z.infer<typeof schema>

        websocketDispatcher.jsonData.value = {}

        try {
            let jsonData: InferredSchemaType

            function matcher(data: any) {
                const success = handleMessage<InferredSchemaType>(schema, toRaw(data))
                if (success) {
                    jsonData = toRaw(data)
                }

                return success
            }

            await until(websocketDispatcher.jsonData).toMatch<InferredSchemaType>(
                // @ts-ignore
                matcher,
                {
                    throwOnTimeout: true,
                    timeout: 0,
                    ...options,
                },
            )

            return jsonData
        } catch (err) {
            throw err
        }
    }

    return {
        provide: {
            wsData: websocketDispatcher.jsonData,
            wsWaitFor: wsWaitFor,
        },
    }
})

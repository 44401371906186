import Toast, { PluginOptions, POSITION, useToast } from 'vue-toastification'
import 'vue-toastification/dist/index.css'

export default defineNuxtPlugin((nuxtApp) => {
    const options: PluginOptions = {
        position: POSITION.TOP_CENTER,
        timeout: 5000,
    }

    nuxtApp.vueApp.use(Toast, options)
    const toast = useToast()

    return {
        provide: {
            toast: toast,
        },
    }
})

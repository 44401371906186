import { z } from 'zod'

// #region PRIMITIVE
export const SessionStateSchema = z.enum(['aborted', 'finished', 'paused', 'running']).nullable()
export const ActivityTypeSchema = z.enum(['qrhunt', 'content'])
// #endregion

// #region RESOURCE TYPES
export const SessionSchema = z.object({
    resourceType: z.literal('session').optional(),
    uid: UUIDSchema,
    state: SessionStateSchema,
    parentActivity: ActivityTypeSchema.nullable(),
    parentSessionUid: UUIDSchema.nullable(),
    params: ParamsSchema.optional().nullable(),
    activity: ActivityTypeSchema,
    attributes: AttributesSchema.nullable(),
})
// #endregion

// #region ERRORS

export const ConflictingSessionStateErrorSchema = ErrorSchema.extend({
    content: ErrorSchema.shape.content.extend({
        errorType: z.literal('conflictingSessionState'),
    }),
})

// #endregion

// #region REQUEST
export const StartSessionRequestMessageSchema = z.object({
    target: z.literal('activity_session_manager'),
    content: z.object({
        type: z.literal('startSession'),
        activity: ActivityTypeSchema,
        params: ParamsSchema,
    }),
})

export const ChangeSessionStateRequestMessageSchema = z.object({
    target: z.literal('activity_session_manager'),
    content: z.object({
        type: z.literal('changeSessionState'),
        action: z.enum(['abort', 'pause', 'resume']),
        activity: ActivityTypeSchema,
        sessionUid: UUIDSchema,
    }),
})

export const GetSessionsRequestMessageSchema = z.object({
    target: z.literal('activity_session_manager'),
    content: z.object({
        type: z.literal('getSessions'),
        sessionStates: z.array(SessionStateSchema).nullable(),
        parent: z.string().nullable(),
        activity: ActivityTypeSchema.nullable(),
    }),
})

export const GetSessionByUidRequestMessageSchema = z.object({
    target: z.literal('activity_session_manager'),
    content: z.object({
        type: z.literal('getSessionByUid'),
        uid: UUIDSchema,
    }),
})
// #endregion

// #region RESPONSE

export const StartSessionResponseSchema = z.object({
    type: z.literal('stateChangeEvent'),
    content: SessionSchema,
})

export const SessionChangeResponseSchema = StartSessionResponseSchema

export const GetSessionsResponseSchema = z.object({
    type: z.literal('resources'),
    content: z.object({
        resourceType: z.literal('session'),
        resources: z.array(SessionSchema),
    }),
})

export const GetSessionResponseSchema = z.object({
    type: z.literal('resource'),
    content: SessionSchema,
})

//#region TYPES

export type SessionState = z.infer<typeof SessionStateSchema>
export type Session = z.infer<typeof SessionSchema>
export type ConflictingSessionStateError = z.infer<typeof ConflictingSessionStateErrorSchema>
export type StartSessionRequest = z.infer<typeof StartSessionRequestMessageSchema>
export type StartSessionResponse = z.infer<typeof StartSessionResponseSchema>
export type SessionChangeRequest = z.infer<typeof ChangeSessionStateRequestMessageSchema>
export type SessionChangeResponse = z.infer<typeof SessionChangeResponseSchema>
export type GetSessionsRequest = z.infer<typeof GetSessionsRequestMessageSchema>
export type GetSessionsResponse = z.infer<typeof GetSessionsResponseSchema>
export type GetSessionByUidRequest = z.infer<typeof GetSessionByUidRequestMessageSchema>
export type GetSessionResponse = z.infer<typeof GetSessionResponseSchema>

// #endregion
